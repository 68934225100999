import {
  getAllClients,
  getAllClientTypes,
  saveClient,
  updateClient,
  deleteClient,
  toggleClientActiveStatus,
} from '@/api/clients';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllClients() {
      return getAllClients();
    },
    async getAllClientTypes() {
      return getAllClientTypes();
    },
    async saveClient(state, item) {
      return saveClient(item);
    },
    async updateClient(state, item) {
      return updateClient(item);
    },
    async deleteClient(state, item) {
      return deleteClient(item);
    },
    async toggleClientActiveStatus(state, item) {
      return toggleClientActiveStatus(item);
    },
  },
  getters: {},
};
