import { translateText } from '@/api/googleTranslate';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async translateText(state, item) {
      return translateText(item);
    },
  },
};
