import { deleteUser, getAllUsers, getAllCollectors, saveUser, updateUser } from '@/api/users';

export default {
  namespaced: true,
  mutations: {},

  actions: {
    async getAllUsers(state, item) {
      return getAllUsers(item);
    },
    async getAllCollectors(state, item) {
      return getAllCollectors(item);
    },
    async saveUser(state, item) {
      return saveUser(item);
    },
    async updateUser(state, item) {
      return updateUser(item);
    },
    async deleteUser(state, item) {
      return deleteUser(item);
    },
  },
  getters: {},
};
