import { getAllOrders, getOrder, saveOrder, updateOrder, finishOrder, cancelOrder } from '@/api/orders';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllOrders(state, item) {
      return getAllOrders(item);
    },
    async getOrder(state, item) {
      return getOrder(item);
    },
    async saveOrder(state, item) {
      return saveOrder(item);
    },
    async updateOrder(state, item) {
      return updateOrder(item);
    },
    async finishOrder(state, item) {
      return finishOrder(item);
    },
    async cancelOrder(state, item) {
      return cancelOrder(item);
    },
  },
  getters: {},
};
