import { getHttpClient } from './client';

export const saveOrderComment = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/order-comment', 'POST', data).then(
    (response) => response.data
  );
};

export const loadOrderComments = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/order-comment';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};
