import { getAllContactMessages } from '@/api/contactMessages';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllContactMessages() {
      return getAllContactMessages();
    },
  },
  getters: {},
};
