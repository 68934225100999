import { getHttpClient } from './client';

export const getAllEvents = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/event').then((response) => response.data);
};

export const saveEvent = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/event', 'POST', data).then((response) => response.data);
};

export const updateEvent = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/event', 'POST', data).then((response) => response.data);
};

export const deleteEvent = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/event/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};
