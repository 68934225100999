import { getHttpClient } from './client';

export const getAllClients = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/client').then((response) => response.data);
};

export const getAllClientTypes = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/client-types').then((response) => response.data);
};

export const saveClient = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/client', 'POST', data).then((response) => response.data);
};

export const updateClient = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/client', 'POST', data).then((response) => response.data);
};

export const deleteClient = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/client/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const toggleClientActiveStatus = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/change-client-active-status/' + data.id, 'POST').then(
    (response) => response.data
  );
};
