import { deleteAlbumItem, getAllAlbumItems, saveAlbumItem } from '@/api/albumItems';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllAlbumItems(state, item) {
      return getAllAlbumItems(item);
    },
    async saveAlbumItem(state, item) {
      return saveAlbumItem(item);
    },
    async deleteAlbumItem(state, item) {
      return deleteAlbumItem(item);
    },
  },
  getters: {},
};
