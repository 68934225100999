import { getAllAlbums, saveAlbum, updateAlbum, deleteAlbum } from '@/api/albums';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllAlbums() {
      return getAllAlbums();
    },
    async saveAlbum(state, item) {
      return saveAlbum(item);
    },
    async updateAlbum(state, item) {
      return updateAlbum(item);
    },
    async deleteAlbum(state, item) {
      return deleteAlbum(item);
    },
  },
  getters: {},
};
