import { saveOrderComment, loadOrderComments } from '@/api/orderComment';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async saveOrderComment(state, item) {
      return saveOrderComment(item);
    },
    async loadOrderComments(state, item) {
      return loadOrderComments(item);
    },
  },
  getters: {},
};
