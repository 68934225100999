import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from '@/store/modules/auth';
import users from '@/store/modules/users';
import notifications from '@/store/modules/notifications';
import clients from '@/store/modules/clients';
import organisations from '@/store/modules/organisations';
import joinRequests from '@/store/modules/joinRequests';
import sponsors from '@/store/modules/sponsors';
import mainBanner from '@/store/modules/mainBanner';
import articles from '@/store/modules/articles';
import events from '@/store/modules/events';
import aboutUs from '@/store/modules/aboutUs';
import contactMessages from '@/store/modules/contactMessages';
import albums from '@/store/modules/albums';
import albumItems from '@/store/modules/albumItems';
import roles from '@/store/modules/roles';
import errorMessages from '@/store/modules/errorMessages';
import charts from '@/store/modules/statisticsCharts';
import orders from '@/store/modules/orders';
import googleMapsAPI from '@/store/modules/googleMapsAPI';
import googleTranslate from '@/store/modules/googleTranslate';
import firebaseToken from '@/store/modules/firebaseToken';
import orderComment from '@/store/modules/orderComment';
import statistic from '@/store/modules/statistic';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
  state: {
    snackbar: {},
    addedNewDrivingCounter: 0,
    notificationDriving: { id: null, reload: true },
  },
  getters: {},
  mutations: {
    SET_SNACKBAR(state, snackbar) {
      state.snackbar = snackbar;
    },
    updatedNewDrivingCounter(state) {
      state.addedNewDrivingCounter++;
    },
    updateNotificationDriving(state, value) {
      state.notificationDriving.id = value;
      state.notificationDriving.reload = true;
    },
    updateNotificationDrivingReload(state) {
      state.notificationDriving.reload = false;
    },
  },
  actions: {
    showSnackbar({ commit }, snackbar) {
      snackbar.active = true;
      commit('SET_SNACKBAR', snackbar);
    },
  },
  modules: {
    auth,
    users,
    notifications,
    clients,
    organisations,
    joinRequests,
    sponsors,
    mainBanner,
    articles,
    events,
    aboutUs,
    contactMessages,
    albums,
    albumItems,
    roles,
    errorMessages,
    charts,
    orders,
    googleMapsAPI,
    googleTranslate,
    firebaseToken,
    orderComment,
    statistic,
  },
});
