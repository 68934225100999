import Vue from 'vue';
import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate';
import i18n from '@/i18n/i18n';
// import store from '@/store';
import {
  alpha,
  alpha_dash,
  alpha_num,
  alpha_spaces,
  between,
  confirmed,
  digits,
  dimensions,
  email,
  excluded,
  ext,
  image,
  oneOf,
  max,
  max_value,
  mimes,
  min,
  min_value,
  numeric,
  regex,
  required,
  required_if,
  size,
  double,
} from 'vee-validate/dist/rules';
import me from '@/validations/me.json';
import meTranslations from '@/locales/me';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

localize({
  me: {
    messages: {
      ...me.messages,
      date_compare: 'Polje vrijeme mora biti poslije vrijeme od',
      range: 'Ovaj broj naloga već postoji',
      compare: 'Maksimum mora biti veći od minumuma',
      numeric_decimal: 'Polje {_field_} mora biti broj (može i decimalni)',
      url: 'Polje {_field_} mora biti validan url',
    },
    names: {
      ...meTranslations.names,
    },
  },
});

extend('alpha', alpha);
extend('alpha_dash', alpha_dash);
extend('alpha_num', alpha_num);
extend('alpha_spaces', alpha_spaces);
extend('between', between);
extend('confirmed', confirmed);
extend('digits', digits);
extend('dimensions', dimensions);
extend('email', email);
extend('excluded', excluded);
extend('ext', ext);
extend('image', image);
extend('oneOf', oneOf);
extend('max', max);
extend('max_value', max_value);
extend('mimes', mimes);
extend('min', min);
extend('min_value', min_value);
extend('numeric', numeric);
extend('regex', regex);
extend('required', required);
extend('required_if', required_if);
extend('size', size);
extend('double', double);

// Define a custom validation rule to compare two dates
extend('date_compare', {
  validate(value, { otherDate }) {
    if (!value || !otherDate) {
      return false; // Both dates must be provided for comparison
    }

    const date1 = formatDate(value);
    const date2 = formatDate(otherDate);

    return date2 <= date1; // Return true if date1 is earlier than date2
  },
  params: ['otherDate'], // Define the parameter names used in the rule
});

const formatDate = (date) => {
  const [day, month, year] = date.split('-').map(Number);

  // Month is zero-based in JavaScript, so subtract 1 from it
  return new Date(year, month - 1, day);
};

// Define a custom validation rule to validate ranges
extend('range', {
  validate(value, { items, index }) {
    for (let i = 0; i < items.length; i++) {
      if (value >= items[i].min && value <= items[i].max && i !== index) {
        return false;
      }
    }

    return true;
  },
  params: ['items', 'index'], // Define the parameter names used in the rule
});

// Define a custom validation rule to compare two ranges
extend('compare', {
  validate(value, { min, max }) {
    if (min >= max) {
      return false;
    }

    return true;
  },
  params: ['min', 'max'], // Define the parameter names used in the rule
});

// Define a custom validation rule to check double number
extend('numeric_decimal', {
  ...numeric, // Use the built-in numeric rule as a base
  validate(value) {
    // Use a regular expression to allow decimal numbers
    if (/^\d+(\.\d{1,2})?$/.test(value)) {
      return {
        valid: true,
      };
    }

    return {
      valid: false,
      message: 'Please enter a valid number.',
    };
  },
});

// Define a custom validation rule to validate url
extend('url', {
  validate(value) {
    if (/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(value)) {
      return true;
    }

    return false;
  },
});

localize(i18n.locale);

// store.watch(
//   (state) => state.language.currentLanguage,
//   (newLanguage) => {
//     localize(newLanguage);
//   }
// );
