import { getHttpClient } from './client';

export const getAllOrders = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/order';
  let queryString = '?';
  if (data.from) {
    queryString += `from=${data.from}`;
  }
  if (data.to) {
    queryString += `&to=${data.to}`;
  }
  if (data.collector_id) {
    queryString += `&collector_id=${data.collector_id}`;
  }
  if (data.client_id) {
    queryString += `&client_id=${data.client_id}`;
  }
  if (data.organisation_id) {
    queryString += `&organisation_id=${data.organisation_id}`;
  }
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getOrder = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/order/' + data).then((response) => response.data);
};

export const saveOrder = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/order', 'POST', data).then((response) => response.data);
};

export const updateOrder = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/order', 'POST', data).then((response) => response.data);
};

export const cancelOrder = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/cancel-order/' + data.id;
  return await getHttpClient(baseUrl, 'GET').then((response) => response.data);
};

export const finishOrder = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/complete-order/' + data.id;
  return await getHttpClient(baseUrl, 'GET', data).then((response) => response.data);
};
