import { getHttpClient, getHttpClientForBlob } from './client';

export const vehicleActivity = async (data) => {
  let type = data.type;
  let queryString = type ? `?type=${type}` : '';
  return await getHttpClient(process.env.VUE_APP_API_URL + '/vehicle-usage' + queryString).then(
    (response) => response.data
  );
};

export const driverAcceptActivity = async (data) => {
  let type = data.type;
  let queryString = type ? `?type=${type}` : '';
  return await getHttpClient(process.env.VUE_APP_API_URL + '/accepted-driving' + queryString).then(
    (response) => response.data
  );
};

export const driverRejectActivity = async (data) => {
  let type = data.type;
  let queryString = type ? `?type=${type}` : '';
  return await getHttpClient(process.env.VUE_APP_API_URL + '/rejected-driving' + queryString).then(
    (response) => response.data
  );
};

export const numberOfDrivings = async (data) => {
  let type = data.type;
  let queryString = type ? `?type=${type}` : '';
  return await getHttpClient(process.env.VUE_APP_API_URL + '/driving-monthly-count' + queryString).then(
    (response) => response.data
  );
};

export const revenuePerVehicle = async (data) => {
  let type = data.type;
  let queryString = type ? `?type=${type}` : '';
  return await getHttpClient(process.env.VUE_APP_API_URL + '/vehicle-revenue' + queryString).then(
    (response) => response.data
  );
};

export const commissionPerDriver = async (data) => {
  let type = data.type;
  let queryString = type ? `?type=${type}` : '';
  return await getHttpClient(process.env.VUE_APP_API_URL + '/calculate-drivers-commission' + queryString).then(
    (response) => response.data
  );
};

export const downloadPDF = async () => {
  return await getHttpClientForBlob(process.env.VUE_APP_API_URL + '/exportPDF').then((response) => response.data);
};

export const getProfitabilityQuarterReport = async (data) => {
  return await getHttpClient(
    process.env.VUE_APP_API_URL + '/profitability-index-quarter/' + data.year + '/' + data.quarter
  );
};

export const getProfitabilityYearReport = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/profitability-index/' + data.year);
};

export const getUtilizationQuarterReport = async (data) => {
  return await getHttpClient(
    process.env.VUE_APP_API_URL + '/utilization-index-quarter/' + data.year + '/' + data.quarter
  );
};

export const getUtilizationYearReport = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/utilization-index/' + data.year);
};
