import { getHttpClient } from './client';

export const getAllAlbums = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/album').then((response) => response.data);
};

export const saveAlbum = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/album', 'POST', data).then((response) => response.data);
};

export const updateAlbum = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/album', 'POST', data).then((response) => response.data);
};

export const deleteAlbum = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/album/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};
