import { submitToken } from '@/api/firebaseToken';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async submitToken(state, item) {
      return submitToken(item);
    },
  },
  getters: {},
};
