import Vue from 'vue';
// import store from '@/store';
import VueI18n from 'vue-i18n';
import meLocale from '@/locales/me.js';

Vue.use(VueI18n);

const i18n = new VueI18n({
  // locale: store.state.language.currentLanguage,
  locale: 'me',
  messages: {
    me: meLocale,
  },
});

// Watch for changes in the Vuex store's currentLanguage and update the VueI18n locale
// store.watch(
//   (state) => state.language.currentLanguage,
//   (newLanguage) => {
//     i18n.locale = newLanguage;
//   }
// );

export default i18n;
