import { getAllNotifications } from '@/api/notifications';

export default {
  namespaced: true,
  mutations: {},

  actions: {
    async getAllNotifications(state, item) {
      return getAllNotifications(item);
    },
  },
  getters: {},
};
