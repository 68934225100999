<template>
  <div>
    <v-dialog v-model="cancelOrderDialog" max-width="290" persistent>
      <v-card>
        <v-toolbar class="text-h5 red" dark>
          {{ $t('order.areYouSure') }}
        </v-toolbar>
        <v-card-text>
          <br />
          {{ $t('order.cancelOrder') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="cancelOrder">
            {{ $t('buttons.yes') }}
          </v-btn>
          <v-btn color="red darken-1" text @click="cancelOrderDialog = false">
            {{ $t('buttons.no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="finishOrderDialog" max-width="290" persistent>
      <v-card>
        <v-toolbar class="text-h5 red" dark>
          {{ $t('order.areYouSure') }}
        </v-toolbar>
        <v-card-text>
          <br />
          <p>{{ $t('order.finishOrder') }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="finishOrder">
            {{ $t('buttons.yes') }}
          </v-btn>
          <v-btn color="red darken-1" text @click="finishOrderDialog = false">
            {{ $t('buttons.no') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="selectedOpen"
      :activator="selectedElement"
      :close-on-content-click="false"
      max-width="800px"
      offset-x
      :key="selectedOpen">
      <v-card color="grey lighten-4" flat min-width="350px">
        <v-toolbar :color="editedOrderItem.color" dark>
          <v-toolbar-title v-model="editedOrderItem.title">
            {{ editedOrderItem.title || $t(formTitle) }}
            {{ editedOrderItem.id ? '-' : '' }}
            {{ editedOrderItem.id && getStatus() }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <template v-if="editedOrderItem.id">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-tooltip left>
                  <template v-slot:activator="{ on: tooltipOn }">
                    <v-btn text v-on="{ ...on, ...tooltipOn }">
                      <v-icon> mdi-dots-vertical </v-icon>
                    </v-btn>
                  </template>
                  <span>
                    {{ $t('buttons.options') }}
                  </span>
                </v-tooltip>
              </template>
              <v-list>
                <v-tooltip v-if="editedOrderItem.id" left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item @click="editItem">
                      <v-list-item-title v-bind="attrs" v-on="on">
                        {{ $t('buttons.edit') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <span>{{ $t('tooltips.edit') }}</span>
                </v-tooltip>

                <v-tooltip v-if="editedOrderItem.id && showMarkAsCanceled" left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item @click="cancelOrderDialog = !cancelOrderDialog">
                      <v-list-item-title v-bind="attrs" v-on="on">
                        {{ $t('buttons.cancelOrder') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <span>
                    {{ $t('tooltips.cancelOrder') }}
                  </span>
                </v-tooltip>

                <v-tooltip v-if="showMarkAsDone" left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item @click="finishOrderDialog = !finishOrderDialog">
                      <v-list-item-title v-bind="attrs" v-on="on">
                        {{ $t('buttons.markAsDone') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <span>
                    {{ $t('tooltips.markAsDone') }}
                  </span>
                </v-tooltip>
              </v-list>
            </v-menu>
          </template>

          <v-icon class="mdi mdi-close mx-1" style="color: white" @click="close"></v-icon>
        </v-toolbar>
        <validation-observer ref="orderForm" v-slot="{ handleSubmit, failed }">
          <v-form lazy-validation @submit.prevent="handleSubmit(saveOrder)">
            <v-tabs v-if="editedOrderItem.id" v-model="activeTab">
              <v-tab>
                {{ $t('order.order') }}
              </v-tab>

              <v-tab :disabled="!disable" v-if="editedOrderItem.id">
                {{ $t('order.comments') }} ({{ editedOrderItem?.order_comments?.length }})
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="activeTab">
              <v-tab-item>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" hidden md="6" sm="12">
                        <v-text-field v-model="editedOrderItem.id" :label="$t('order.id')"></v-text-field>
                      </v-col>

                      <v-col cols="12" lg="12" md="12" sm="12">
                        <validation-provider rules="max:200" v-slot="{ errors }" name="name">
                          <v-text-field
                            v-model="editedOrderItem.title"
                            :disabled="disable"
                            name="title"
                            clearable
                            counter="200"
                            :label="$t('order.title')"
                            :error-messages="errors"></v-text-field>
                        </validation-provider>
                      </v-col>

                      <v-col cols="12" lg="6" md="6" sm="12">
                        <gmap-autocomplete
                          :options="googleMapsOptions"
                          :setFieldsTo="GoogleApiFields"
                          :value="editedOrderItem.pickup_location"
                          selectFirstOnEnter
                          @place_changed="selectPickupLocation">
                          <template v-slot:default="slotProps">
                            <validation-provider v-slot="{ errors }" name="location_from">
                              <v-text-field
                                ref="input"
                                v-model="editedOrderItem.pickup_location_name"
                                :disabled="disable"
                                :label="$t('order.pickupLocation')"
                                @focusout="selectPickupLocationFocusout"
                                v-on:attrs="slotProps.attrs"
                                v-on:listeners="slotProps.listeners"
                                :error-messages="errors"
                                @keydown.enter.prevent>
                              </v-text-field>
                            </validation-provider>
                          </template>
                        </gmap-autocomplete>
                      </v-col>

                      <v-col cols="12" lg="6" md="6" sm="12">
                        <v-menu
                          ref="pickupTime"
                          v-model="pickupTime"
                          :close-on-content-click="false"
                          :return-value.sync="editedOrderItem.pickup_time"
                          min-width="auto"
                          offset-y
                          transition="scale-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <validation-provider rules="required" v-slot="{ errors }" name="pickup_time">
                              <v-text-field
                                v-model="editedOrderItem.pickup_time"
                                :disabled="disable"
                                :label="$t('order.pickupTime')"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                :error-messages="errors"></v-text-field>
                            </validation-provider>
                          </template>
                          <DatePicker
                            v-model="editedOrderItem.pickup_time"
                            :minute-increment="1"
                            :model-config="modelConfig"
                            class="no-border"
                            is24hr
                            mode="dateTime">
                          </DatePicker>
                          <div class="date-picker-buttons">
                            <v-btn class="primary mr-2" elevation="2" text @click="pickupTime = false">
                              {{ $t('buttons.cancel') }}
                            </v-btn>
                            <v-btn class="primary mr-2" elevation="2" text @click="updatePickupTime">
                              {{ $t('buttons.confirm') }}
                            </v-btn>
                          </div>
                        </v-menu>
                      </v-col>

                      <v-col cols="12" lg="6" md="6" sm="12">
                        <validation-provider rules="required" v-slot="{ errors }" name="collector">
                          <v-autocomplete
                            v-model="editedOrderItem.collector_id"
                            :disabled="disable"
                            :items="collectors"
                            clearable
                            item-text="name"
                            item-value="id"
                            :label="$t('order.collector')"
                            :no-data-text="$t('select.noDataAvailable')"
                            @focus="$event.target.click()"
                            :error-messages="errors"></v-autocomplete>
                        </validation-provider>
                      </v-col>

                      <v-col cols="12" lg="6" md="6" sm="12">
                        <validation-provider rules="required" v-slot="{ errors }" name="client">
                          <v-autocomplete
                            v-model="editedOrderItem.client_id"
                            :disabled="disable"
                            :items="clients"
                            clearable
                            item-text="company_name"
                            item-value="id"
                            :label="$t('order.client')"
                            :no-data-text="$t('select.noDataAvailable')"
                            @focus="$event.target.click()"
                            :error-messages="errors"></v-autocomplete>
                        </validation-provider>
                      </v-col>

                      <v-col cols="6" lg="3" md="3" sm="6">
                        <validation-provider rules="required|numeric|max:200" v-slot="{ errors }" name="glass_amount">
                          <v-text-field
                            v-model="editedOrderItem.glass_amount"
                            :disabled="disable"
                            name="glass_amount"
                            clearable
                            counter="200"
                            :label="$t('order.glassAmount')"
                            :error-messages="errors"></v-text-field>
                        </validation-provider>
                      </v-col>

                      <v-col cols="6" lg="3" md="3" sm="6">
                        <validation-provider rules="required|numeric|max:200" v-slot="{ errors }" name="paper_amount">
                          <v-text-field
                            v-model="editedOrderItem.paper_amount"
                            :disabled="disable"
                            name="paper_amount"
                            clearable
                            counter="200"
                            :label="$t('order.paperAmount')"
                            :error-messages="errors"></v-text-field>
                        </validation-provider>
                      </v-col>

                      <v-col cols="6" lg="3" md="3" sm="6">
                        <validation-provider rules="required|numeric|max:200" v-slot="{ errors }" name="metal_amount">
                          <v-text-field
                            v-model="editedOrderItem.metal_amount"
                            :disabled="disable"
                            name="metal_amount"
                            clearable
                            counter="200"
                            :label="$t('order.metalAmount')"
                            :error-messages="errors"></v-text-field>
                        </validation-provider>
                      </v-col>

                      <v-col cols="6" lg="3" md="3" sm="6">
                        <validation-provider rules="required|numeric|max:200" v-slot="{ errors }" name="plastic_amount">
                          <v-text-field
                            v-model="editedOrderItem.plastic_amount"
                            :disabled="disable"
                            name="plastic_amount"
                            clearable
                            counter="200"
                            :label="$t('order.plasticAmount')"
                            :error-messages="errors"></v-text-field>
                        </validation-provider>
                      </v-col>

                      <v-col cols="12" md="12" sm="12">
                        <validation-provider rules="max:1000" v-slot="{ errors }" name="description">
                          <v-textarea
                            v-model="editedOrderItem.description"
                            :disabled="disable"
                            clearable
                            counter="1000"
                            :label="$t('order.description')"
                            rows="2"
                            :error-messages="errors"></v-textarea>
                        </validation-provider>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions v-if="[0, 1, 2].includes(activeTab)">
                  <v-spacer></v-spacer>
                  <button-submit
                    :failed="disable || failed"
                    :loading="loadingOrder"
                    buttonText="buttons.save"></button-submit> </v-card-actions
              ></v-tab-item>

              <v-tab-item v-if="editedOrderItem.id && disable">
                <v-card-text>
                  <v-container>
                    <validation-observer :key="activeTab" ref="orderCommentForm" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(saveComment)">
                        <v-row>
                          <v-col cols="12" md="12" sm="12">
                            <validation-provider rules="required|max:1000" v-slot="{ errors }" name="comment">
                              <v-textarea
                                v-model="editedOrderComment.body"
                                clearable
                                counter="1000"
                                :label="$t('order.comment')"
                                rows="2"
                                :error-messages="errors"></v-textarea>
                            </validation-provider>
                          </v-col>
                          <v-col cols="12" md="12" sm="12">
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <button-submit
                                :failed="failed"
                                :loading="loadingComment"
                                buttonText="buttons.save"></button-submit>
                            </v-card-actions>
                          </v-col>
                          <v-col
                            v-for="comment in editedOrderItem?.order_comments"
                            :key="comment.id"
                            cols="12"
                            md="12"
                            sm="12">
                            <v-banner outlined rounded shaped single-line sticky>
                              <p>
                                <b>{{ comment?.user?.first_name + ' ' + comment?.user?.last_name }}</b>
                                <span style="float: right">{{ comment?.created_at }}</span>
                              </p>
                              <p style="text-wrap: initial">{{ comment.body }}</p>
                              <p v-if="parseInt(comment.rate)">Ocjena: {{ comment.rate }}</p>
                            </v-banner>
                          </v-col>
                        </v-row>
                      </v-form>
                    </validation-observer>
                  </v-container>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-form>
        </validation-observer>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import i18n from '@/i18n/i18n';
import { defaultOrder, defaultOrderComment } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import { DatePicker } from 'v-calendar/src/components';
import { formatDate } from '@/utils/formatDate';

export default {
  name: 'OrderModal',
  components: { DatePicker, ButtonSubmit },
  props: ['selectedElement', 'isModalOpen', 'disabled'],
  data: () => ({
    activeTab: 0,
    value: '',
    order: {},
    selectedOpen: false,
    editedOrderItem: {},
    editedOrderComment: {},
    enableOrderCommentSave: true,
    enableOrderSave: true,
    formTitle: 'order.newTransfer',
    collectors: [],
    clients: [],
    GoogleApiFields: ['name', 'formatted_address', 'geometry', 'place_id', 'plus_code', 'url'],
    pickupTime: null,
    cancelOrderDialog: false,
    finishOrderDialog: false,
    disable: true,
    googleMapsOptions: {
      bounds: {
        north: 45.830329,
        south: 41.51274,
        east: 20.834725,
        west: 16.332208,
      },
      // componentRestrictions: {country: ["me", "hr", "rs", "ba", "al"]},
      strictBounds: false,
    },
    min: null,
    max: null,
    modelConfig: {
      type: 'string',
      mask: 'DD-MM-YYYY HH:mm',
    },
    timeModelConfig: {
      type: 'string',
      mask: 'HH:mm',
    },
    loadingOrder: false,
    loadingComment: false,
    showDrivers: false,
  }),
  created() {
    this.editedOrderItem = Object.assign({}, defaultOrder);
    this.editedOrderComment = Object.assign({}, defaultOrderComment);
    this.getAllCollectors();
    this.getAllClients();
    if (this.isModalOpen) {
      this.selectedOpen = true;
    }

    this.disable = this.disabled;
  },
  computed: {
    showMarkAsDone() {
      const proposal = this.editedOrderItem?.status;
      return (
        this.editedOrderItem?.id &&
        proposal &&
        proposal !== 'canceled' &&
        proposal !== 'completed' &&
        new Date(this.editedOrderItem.pickup_time) < new Date()
      );
    },
    showMarkAsCanceled() {
      const proposal = this.editedOrderItem?.status;

      return this.editedOrderItem?.id && proposal && proposal !== 'canceled' && proposal !== 'completed';
    },
  },
  methods: {
    async getAllCollectors() {
      await this.$store.dispatch('users/getAllCollectors').then((res) => {
        this.collectors = res.data.length
          ? res.data.map((user) => {
              user.name = user?.first_name + ' ' + user?.last_name || 'N/A';

              return user;
            })
          : [];
      });
    },

    async getAllClients() {
      await this.$store.dispatch('clients/getAllClients').then((res) => {
        this.clients = res.data.length
          ? res.data.map((item) => {
              return item;
            })
          : [];
      });
    },

    editItem() {
      this.disable = false;
    },

    showEvent(event) {
      if (event) {
        this.selectedOpen = true;
        this.editedOrderItem = Object.assign({}, event);
        this.editedOrderComment = Object.assign({}, defaultOrderComment);
        this.editedOrderItem?.order_comments.reverse();
        this.editedOrderComment.order_id = parseInt(event.id);
        this.editedOrderItem.pickup_location = event.pickup_location ? JSON.parse(event.pickup_location) : null;
        this.editedOrderItem.pickup_location_name = this.editedOrderItem.pickup_location?.name;
        this.editedOrderItem.collector_id = event.collector_id ? parseInt(event?.collector_id) : null;
        this.editedOrderItem.client_id = event.client_id ? parseInt(event?.client_id) : null;

        this.$store
          .dispatch('orderComment/loadOrderComments', { order_id: this.editedOrderItem.id })
          .then((res) => {
            this.editedOrderItem.order_comments = res.data.reverse();
            event.order_comments = this.editedOrderItem?.order_comments.map((item) => {
              item.created_at = formatDate(item.created_at);
            });
          })
          .catch(() =>
            this.$store.dispatch('showSnackbar', {
              text: i18n.t('snackbar.loadingCommentsFailed'),
              color: 'red',
            })
          );
        this.disable = true;
      } else {
        this.disable = false;

        this.editedOrderItem.pickup_time = this.pickup_time_preselect;
      }
    },

    createFormData() {
      let formData = new FormData();

      formData.append('id', this.editedOrderItem.id ? this.editedOrderItem.id : '');
      formData.append(
        'pickup_location',
        this.editedOrderItem.pickup_location ? JSON.stringify(this.editedOrderItem.pickup_location) : ''
      );
      formData.append('description', this.editedOrderItem.description ? this.editedOrderItem.description : '');
      formData.append('pickup_time', this.editedOrderItem.pickup_time ? this.editedOrderItem.pickup_time : '');
      formData.append('collector_id', this.editedOrderItem.collector_id ? this.editedOrderItem.collector_id : '');
      formData.append('title', this.editedOrderItem.title ? this.editedOrderItem.title : '');
      formData.append('glass_amount', this.editedOrderItem.glass_amount ? this.editedOrderItem.glass_amount : 0);
      formData.append('paper_amount', this.editedOrderItem.paper_amount ? this.editedOrderItem.paper_amount : 0);
      formData.append('metal_amount', this.editedOrderItem.metal_amount ? this.editedOrderItem.metal_amount : 0);
      formData.append('plastic_amount', this.editedOrderItem.plastic_amount ? this.editedOrderItem.plastic_amount : 0);
      formData.append('client_id', this.editedOrderItem.client_id ? this.editedOrderItem.client_id : '');

      return formData;
    },

    saveOrder() {
      this.enableOrderSave = false;
      this.loadingOrder = true;

      let formData = this.createFormData();

      this.$store
        .dispatch('orders/saveOrder', formData)
        .then((res) => {
          this.editedOrderItem.id = res.data?.id;
          this.disable = true;
          this.selectedOpen = false;
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.$store.commit('updatedNewDrivingCounter');
          this.$emit('loadAllOrders');
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enableOrderSave = true;
          this.loadingOrder = false;
        });
    },
    selectPickupLocation(input) {
      this.editedOrderItem.pickup_location = input;
      this.editedOrderItem.pickup_location_name = input.name + ` (${input.formatted_address})`;
    },

    selectPickupLocationFocusout(input) {
      let value = input.target.value;
      if (
        value !== this.editedOrderItem.pickup_location?.name &&
        value !==
          this.editedOrderItem.pickup_location?.name + ` (${this.editedOrderItem.pickup_location?.formatted_address})`
      ) {
        this.editedOrderItem.pickup_location = { name: value };
        this.editedOrderItem.pickup_location_name = value;
      }
    },

    updatePickupTime() {
      this.$refs.pickupTime.save(this.editedOrderItem.pickup_time);
    },

    async saveComment() {
      if (this.$refs.orderCommentForm.validate()) {
        this.enableOrderCommentSave = false;
        this.loadingComment = true;
        await this.$store
          .dispatch('orderComment/saveOrderComment', this.editedOrderComment)
          .then((res) => {
            this.editedOrderItem?.order_comments.unshift({ ...res.data, created_at: formatDate(res.data.created_at) });
            this.$emit('loadAllOrders');
            this.editedOrderComment = Object.assign({}, { ...defaultOrderComment, order_id: this.editedOrderItem.id });
            this.$refs.orderCommentForm.reset();
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          })
          .catch((error) => {
            if (error.response?.status === 422) {
              this.$store
                .dispatch('errorMessages/errorMapper', error.response.data.message)
                .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
            } else {
              this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
            }
          })
          .finally(() => {
            this.enableOrderCommentSave = true;
            this.loadingComment = false;
          });
      }
    },
    async cancelOrder() {
      this.cancelOrderDialog = false;
      await this.$store
        .dispatch('orders/cancelOrder', this.editedOrderItem)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.orderCanceled'), color: 'green' });
          this.selectedOpen = false;
          this.$emit('loadAllOrders');
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },
    async finishOrder() {
      this.finishOrderDialog = false;
      const body = {
        id: this.editedOrderItem.id,
      };
      await this.$store
        .dispatch('orders/finishOrder', body)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.orderFinished'), color: 'green' });
          this.selectedOpen = false;
          this.$emit('loadAllOrders');
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },
    close() {
      this.selectedOpen = false;
    },

    getStatus() {
      const orderStatus = {
        accepted: 'orderStatus.accepted',
        client_pending: 'orderStatus.client_pending',
        collector_pending: 'orderStatus.collector_pending',
        canceled: 'orderStatus.canceled',
        completed: 'orderStatus.completed',
        deleted: 'orderStatus.deleted',
      };
      const proposal = this.editedOrderItem?.status;
      let id;
      if (this.editedOrderItem?.id && proposal) {
        id = this.editedOrderItem?.status;
      }

      return orderStatus[id] ? i18n.t(orderStatus[id]) : '';
    },
  },
  watch: {
    selectedOpen(val) {
      if (!val) {
        this.editedOrderItem = Object.assign({}, defaultOrder);
        this.editedOrderComment = Object.assign({}, defaultOrderComment);

        if (this.$refs.orderCommentForm) {
          this.$refs.orderCommentForm.reset();
        }
        if (this.$refs.orderForm) {
          this.$refs.orderForm.reset();
        }
        this.$refs.orderForm.reset();
        this.activeTab = 0;
      }
      this.$emit('disableDisplay');
    },
    isModalOpen: {
      handler() {
        if (this.isModalOpen) {
          this.selectedOpen = true;
          this.disable = false;
          this.getAllCollectors();
          this.getAllClients();
        }
      },
    },
    selectedElement: {
      handler() {
        this.showEvent(this.selectedElement);
        this.selectedOpen = true;
      },
      deep: false,
    },
  },
};
</script>
