import { getHttpClient } from '@/api/client';

export const login = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/login', 'POST', data).then((response) => response.data);
};

export const saveUser = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/admin/save-user', 'POST', data).then(
    (response) => response.data
  );
};

export const dismissModal = () => {
  getHttpClient(process.env.VUE_APP_API_URL + '/dismiss-modal', 'POST');
};

export const verifyUserAndSetPassword = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/set-pass-change', 'POST', data).then(
    (response) => response.data
  );
};
