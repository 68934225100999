import { getAllJoinRequests, deleteJoinRequest } from '@/api/joinRequests';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllJoinRequests() {
      return getAllJoinRequests();
    },
    async deleteJoinRequest(state, item) {
      return deleteJoinRequest(item);
    },
  },
  getters: {},
};
