<template>
  <v-app>
    <AuthenticatedLayout v-if="this.$store.state.auth.authenticated && this.$store.state.auth.user" />

    <UnauthenticatedLayout v-else />

    <v-snackbar v-model="snackbar.active" :color="snackbar.color" :timeout="2000" bottom>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.active = false">
          {{ $t('snackbar.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import UnauthenticatedLayout from '@/layouts/UnauthenticatedLayout';
import AuthenticatedLayout from '@/layouts/AuthenticatedLayout';
import { mapState } from 'vuex';
import state from '@/store';

export default {
  name: 'App',
  components: {
    AuthenticatedLayout,
    UnauthenticatedLayout,
  },
  data: () => ({ display: false, displayForm: false }),
  computed: {
    ...mapState(['snackbar']),
  },
  created() {
    let GreenWaveUser = localStorage.getItem('GreenWaveUser');
    let parsedUser = GreenWaveUser ? JSON.parse(GreenWaveUser) : null;
    if (
      localStorage.getItem('GreenWaveToken') &&
      parsedUser &&
      localStorage.getItem('loggedIn') &&
      localStorage.getItem('saveToken')
    ) {
      state.dispatch('auth/setUser', parsedUser);
      state.dispatch('auth/setRole', parsedUser);
      state.dispatch('auth/setAuthToken', localStorage.getItem('GreenWaveToken'));
      state.dispatch('auth/setAuthenticated', true);
      if (this.$route.name === 'Login' || window.location.pathname === '/login' || window.location.pathname === '/') {
        this.$router.push({ name: 'HomeCalendarView' });
      }
    }

    if (!localStorage.getItem('loggedIn')) {
      state.dispatch('auth/logout');
    }

    this.snackbar.active = false;
  },
  metaInfo() {
    return {
      title: process.env.VUE_APP_BROWSER_TITLE,
      meta: [
        { name: 'description', content: 'Zeleni talas' },
        { property: 'og:title', content: 'Zeleni talas' },
        { property: 'og:site_name', content: 'Zeleni talas' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  methods: {
    calculateRemainingHours(date) {
      return Math.round((new Date(date) - new Date()) / (1000 * 60 * 60));
    },
  },
};
</script>

<style>
.v-card__actions {
  padding: 16px;
}

.v-dialog > .v-card > .v-card__actions {
  padding: 16px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}

.date-picker button {
  width: 50% !important;
}

.add-button {
  z-index: 22;
  position: fixed;
  bottom: 70px;
  right: 10px;
  width: 60px;
  height: 60px;
  font-size: 40px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1976d2;
  border-radius: 50%;
  color: white;
}

.add-button > .rotate-right {
  transform: rotate(45deg);
  transition-duration: 0.5s;
}

.add-button > .rotate-left {
  transform: rotate(0deg);
  transition-duration: 1s;
}

.margin-large {
  margin-bottom: 112px;
}
</style>
