import { deleteMainBanner, getAllMainBanners, saveMainBanner, updateMainBanner } from '@/api/mainBanner';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllMainBanners() {
      return getAllMainBanners();
    },
    async saveMainBanner(state, item) {
      return saveMainBanner(item);
    },
    async updateMainBanner(state, item) {
      return updateMainBanner(item);
    },
    async deleteMainBanner(state, item) {
      return deleteMainBanner(item);
    },
  },
  getters: {},
};
