import { getHttpClient } from './client';

export const getAllArticles = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/article').then((response) => response.data);
};

export const saveArticle = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/article', 'POST', data).then((response) => response.data);
};

export const updateArticle = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/article', 'POST', data).then((response) => response.data);
};

export const deleteArticle = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/article/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const toggleArticleActiveStatus = async (data) => {
  return await getHttpClient(
    process.env.VUE_APP_API_URL + '/change-article-active-status/' + data.id,
    'POST'
  ).then((response) => response.data);
};

