import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store/index';

Vue.use(VueRouter);
const routePrefix = process.env.VUE_APP_ROUTE_PREFIX;

const routes = [
  {
    path: routePrefix + '/orders/calendar-view',
    name: 'HomeCalendarView',
    component: () => import(/* webpackChunkName: "home" */ '@/views/HomePage'),
    meta: {
      auth: true,
      roles: [1, 3],
    },
  },
  {
    path: routePrefix + '/orders/tabular-view',
    name: 'HomeTabularView',
    component: () => import(/* webpackChunkName: "homeTabularView" */ '@/views/orders/TabularView'),
    meta: {
      auth: true,
      roles: [1, 3],
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login'),
    meta: {
      auth: false,
    },
    params: true,
    props: true,
  },
  {
    path: '/clients',
    name: 'Clients',
    component: () => import(/* webpackChunkName: "clients" */ '@/views/ClientsPage'),
    meta: {
      auth: true,
      roles: [1, 2, 3],
    },
  },
  {
    path: '/organisations',
    name: 'Organisations',
    component: () => import(/* webpackChunkName: "organisations" */ '@/views/OrganisationsPage'),
    meta: {
      auth: true,
      roles: [1, 2, 3],
    },
  },
  {
    path: '/join-requests',
    name: 'JoinRequests',
    component: () => import(/* webpackChunkName: "joinRequests" */ '@/views/JoinRequestsPage'),
    meta: {
      auth: true,
      roles: [1, 2, 3],
    },
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import(/* webpackChunkName: "users" */ '@/views/UsersPage'),
    meta: {
      auth: true,
      roles: [1, 2, 3],
    },
    params: true,
  },
  {
    path: '/statistics',
    name: 'Statistics',
    component: () => import(/* webpackChunkName: "costEffectivenessReport" */ '@/views/statistics/StatisticsPage.vue'),
    meta: {
      auth: true,
      roles: [1, 2, 3],
    },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: () => import(/* webpackChunkName: "notifications" */ '@/views/NotificationsPage'),
    meta: {
      auth: true,
      roles: [1, 2, 3],
    },
  },
  {
    path: '/albums',
    name: 'Albums',
    component: () => import(/* webpackChunkName: "albums" */ '@/views/AlbumsPage'),
    meta: {
      auth: true,
      roles: [1, 2],
    },
  },
  {
    path: '/albums/:albumId',
    name: 'AlbumsItems',
    component: () => import(/* webpackChunkName: "albumsItems" */ '@/views/AlbumsItemsPage'),
    meta: {
      auth: true,
      roles: [1, 2],
    },
  },
  {
    path: '/events',
    name: 'Events',
    component: () => import(/* webpackChunkName: "EventsPage" */ '@/views/EventsPage'),
    meta: {
      auth: true,
      roles: [1, 2],
    },
  },
  {
    path: '/contact-messages',
    name: 'ContactMessages',
    component: () => import(/* webpackChunkName: "ContactMessages" */ '@/views/ContactMessages'),
    meta: {
      auth: true,
      roles: [1, 2],
    },
  },
  {
    path: '/articles',
    name: 'Article',
    component: () => import(/* webpackChunkName: "Blog" */ '@/views/ArticlePage'),
    meta: {
      auth: true,
      roles: [1, 2],
    },
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: () => import(/* webpackChunkName: "AboutUs" */ '@/views/AboutUsPage'),
    meta: {
      auth: true,
      roles: [1, 2],
    },
  },
  {
    path: '/main-banner',
    name: 'MainBanner',
    component: () => import(/* webpackChunkName: "BannerHomePage" */ '@/views/MainBanner'),
    meta: {
      auth: true,
      roles: [1, 2],
    },
  },
  {
    path: '/sponsors',
    name: 'Sponsors',
    component: () => import(/* webpackChunkName: "Blog" */ '@/views/SponsorsPage'),
    meta: {
      auth: true,
      roles: [1, 2],
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('@/views/NotFoundPage'), // or your custom 404 component
  },
];

export const rolesMap = [
  {
    role: 'masterAdmin',
    id: 1,
    redirect: 'HomeCalendarView',
  },
  {
    role: 'websiteAdmin',
    id: 2,
    redirect: 'Users',
  },
  {
    role: 'orderAdmin',
    id: 3,
    redirect: 'HomeCalendarView',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  rolesMap,
});

router.beforeEach((to, from, next) => {
  let roleId = store.getters['auth/role']
    ? parseInt(store.getters['auth/role'])
    : localStorage.getItem('GreenWaveUser')
    ? JSON.parse(localStorage.getItem('GreenWaveUser')).role_id
    : '';

  let token = store.getters['auth/authToken']
    ? store.getters['auth/authToken']
    : localStorage.getItem('GreenWaveToken')
    ? localStorage.getItem('GreenWaveToken')
    : '';

  if (to.matched.some((record) => record.meta.auth)) {
    if (token) {
      const userRole = rolesMap.find((e) => e.id === roleId);
      let userHasRight = validateUserPermissions(to, userRole);

      // Check if user does not have right to access route
      if (!userHasRight) {
        // You can redirect to another route or prevent navigation here
        next({ name: userRole.redirect });
      }
      next();
    } else {
      next({ name: 'Login' }); // if not auth then login
    }
  } else {
    if (token) {
      const userRole = rolesMap.find((e) => e.id === roleId);
      next({ name: userRole.redirect }); // if auth and try login, go home
    } else {
      next();
    }
  }
});

function validateUserPermissions(to, userRole) {
  let final = false;
  routes.forEach((route) => {
    if (route.name === to.name && route.name !== 'Login') {
      if (route.meta.roles.includes(parseInt(userRole.id))) final = true;
    } else if (route.to === 'Login') {
      final = true;
    }
  });
  return final;
}

export default router;
