import { getHttpClient } from './client';

export const getAllUsers = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/user';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getAllCollectors = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/collectors';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const saveUser = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/user', 'POST', data).then((response) => response.data);
};

export const updateUser = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/user', 'POST', data).then((response) => response.data);
};

export const deleteUser = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/user/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};
