import { getAboutUs, saveAboutUs } from '@/api/aboutUs';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAboutUs() {
      return getAboutUs();
    },
    async saveAboutUs(state, item) {
      return saveAboutUs(item);
    },
  },
  getters: {},
};
