import {
  saveOrganisation,
  updateOrganisation,
  deleteOrganisation
} from '@/api/organisations';
import {getAllOrganisations} from "@/api/organisations";

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllOrganisations() {
      return getAllOrganisations();
    },
    async saveOrganisation(state, item) {
      return saveOrganisation(item);
    },
    async updateOrganisation(state, item) {
      return updateOrganisation(item);
    },
    async deleteOrganisation(state, item) {
      return deleteOrganisation(item);
    },
  },
  getters: {},
};
