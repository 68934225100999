import { getHttpClient } from './client';

export const getAllOrganisations = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation').then((response) => response.data);
};

export const saveOrganisation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation', 'POST', data).then((response) => response.data);
};

export const updateOrganisation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation', 'POST', data).then((response) => response.data);
};

export const deleteOrganisation = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/organisation/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};
