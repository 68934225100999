import {
  deleteArticle,
  getAllArticles,
  saveArticle,
  toggleArticleActiveStatus,
  updateArticle,
} from '@/api/articles';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllArticles() {
      return getAllArticles();
    },
    async saveArticle(state, item) {
      return saveArticle(item);
    },
    async updateArticle(state, item) {
      return updateArticle(item);
    },
    async deleteArticle(state, item) {
      return deleteArticle(item);
    },
    async toggleArticleActiveStatus(state, item) {
      return toggleArticleActiveStatus(item);
    },
  },
  getters: {},
};
