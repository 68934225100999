<template>
  <v-bottom-navigation class="primary white--text d-flex align-center" fixed padless>
    <p class="mb-0">
      {{ $t('footer.poweredBy') }}
      <strong>
        <a href="https://goldbear.me" target="_blank" id="powerBy">GoldBear Technologies</a>
      </strong>
    </p>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: 'FooterComponent',
};
</script>

<style>
#powerBy {
  text-decoration: none;
  color: white;
}
</style>
