import { getHttpClient } from './client';

export const getAllUsers = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/user';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const translateText = async (data) => {
  let apiKey = process.env.VUE_APP_GOOGLE_API_KEY;
  let url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

  return await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};
