import { getGoogleMapsAPI } from 'gmap-vue';

export const getDistanceBetweenTwoPlaces = async ({ from, to }) => {
  try {
    const google = await getGoogleMapsAPI();
    const distanceMatrixService = new google.maps.DistanceMatrixService();
    const fromLat =
      typeof from?.geometry?.location?.lat === 'number'
        ? from?.geometry?.location?.lat
        : from?.geometry?.location?.lat();
    const fromLng =
      typeof from?.geometry?.location?.lng === 'number'
        ? from?.geometry?.location?.lng
        : from?.geometry?.location?.lng();
    const toLat =
      typeof to?.geometry?.location?.lat === 'number' ? to?.geometry?.location?.lat : to?.geometry?.location?.lat();
    const toLng =
      typeof to?.geometry?.location?.lng === 'number' ? to?.geometry?.location?.lat : to?.geometry?.location?.lng();

    if ((fromLat === 0 || fromLat) && (fromLng === 0 || fromLng) && (toLat === 0 || toLat) && (toLng === 0 || toLng)) {
      let responseData = await distanceMatrixService.getDistanceMatrix({
        origins: [
          {
            lat: fromLat,
            lng: fromLng,
          },
        ],
        destinations: [
          {
            lat: toLat,
            lng: toLng,
          },
        ],
        travelMode: 'DRIVING',
      });
      if (responseData.rows[0]?.elements[0]) {
        if (responseData.rows[0]?.elements[0].status === 'OK') {
          return responseData.rows[0].elements[0];
        }
        return null;
      }
    }
    return null;
  } catch (e) {
    return null;
  }
};
