import {
  deleteSponsor,
  getAllSponsors,
  saveSponsor,
  toggleSponsorActiveStatus,
  updateSponsor,
  orderSponsors,
} from '@/api/sponsors';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllSponsors() {
      return getAllSponsors();
    },
    async saveSponsor(state, item) {
      return saveSponsor(item);
    },
    async updateSponsor(state, item) {
      return updateSponsor(item);
    },
    async deleteSponsor(state, item) {
      return deleteSponsor(item);
    },
    async toggleSponsorActiveStatus(state, item) {
      return toggleSponsorActiveStatus(item);
    },
    async orderSponsors(state, item) {
      return orderSponsors(item);
    },
  },
  getters: {},
};
