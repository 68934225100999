export default {
  sidebar: {
    administration: 'Administracija',
    administrationWebsite: 'Vebsajt',
    users: 'Korisnici',
    usersAdministration: 'Administracija korisnika',
    clients: 'Klijenti',
    organisations: 'Organizacije - Regije',
    clientsAdministration: 'Administracija klijenata',
    organisationsAdministration: 'Administracija organizacija',
    statistics: 'Statistika',
    orders: 'Nalozi',
    calendar: 'Kalendar',
    tabularView: 'Tabelarni prikaz',
    client: 'Klijent',
    clientAdministration: 'Administracija klijenta',
    joinRequests: 'Zahtjevi za registraciju',
    joinRequestsAdministration: 'Administracija zahtjeva za registraciju',
    sponsors: 'Sponzori',
    sponsorsAdministration: 'Administracija sponzora',
    albums: 'Albumi',
    albumsAdministration: 'Administracija albuma',
    article: 'Blog',
    articleAdministration: 'Administracija bloga',
    aboutUs: 'O nama',
    aboutUsAdministration: 'Administracija o nama',
    mainBanner: 'Baner na početnoj strani',
    mainBannerAdministration: 'Administracija banera na početnoj strani',
    events: 'Događaji',
    eventsAdministration: 'Administracija događaja',
    contactMessages: 'Poruke',
    contactMessagesAdministration: 'Administracija poruka',
    logout: 'Odjava iz sistema',
    logoutText: 'Da li ste sigurni da želite da se odjavite iz sistema?',
    unaccessibleRoutes: 'Ne možete pristupiti ovoj stranici. Nadogradite paket za potpuni pristup.',
  },
  header: {
    client: 'Klijent',
    newOrder: 'Novi nalog',
    notifications: 'Obavještenja',
  },
  footer: {
    poweredBy: 'Powered by',
  },
  buttons: {
    ok: 'Ok',
    yes: 'Da',
    no: 'Ne',
    save: 'Sačuvaj',
    send: 'Pošalji',
    sendMessage: 'Pošalji poruku',
    edit: 'Izmijeni',
    add: 'Dodaj',
    confirm: 'Potvrdi',
    cancel: 'Otkaži',
    cancelOrder: 'Otkaži nalog',
    markAsDone: 'Označi kao odrađeno',
    newClient: 'Novi klijent',
    newOrganisation: 'Nova organizacija',
    newSponsor: 'Novi sponzor',
    newBanner: 'Novi baner',
    newArticle: 'Novi blog',
    newAlbum: 'Novi album',
    newEvent: 'Novi događaj',
    newAlbumItem: 'Nova slika',
    downloadStats: 'Preuzmi statistiku',
    downloadPDF: 'Preuzmi PDF',
    today: 'Danas',
    choose: 'Izaberi',
    login: 'Prijavi se',
    showImage: 'Prikaži sliku',
    showAlbum: 'Prikaži album',
    options: 'Opcije',
    actions: 'Akcije',
    delete: 'Izbriši',
    open: 'Otvori',
    show: 'Prikaži',
    monthlyPricing: 'Mjesečna pretplata',
    annualPricing: 'Godišnja pretplata',
    prev: 'Prethodno',
    next: 'Sljedeće',
    skip: 'Preskoči',
    finish: 'Završi',
    accept: 'Prihvati',
  },
  tooltips: {
    save: 'Sačuvaj',
    edit: 'Izmijeni',
    delete: 'Izbriši',
    showImage: 'Prikaži sliku',
    open: 'Otvori',
    show: 'Prikaži',
    cancel: 'Otkaži',
    cancelOrder: 'Otkaži nalog',
    markAsDone: 'Označi kao odrađeno',
    accept: 'Prihvati',
  },
  snackbar: {
    close: 'Zatvori',
    savedSuccessfully: 'Uspješno sačuvano',
    excelDownloadedSuccessfully: 'Excel file je skinut uspješno',
    deletedSuccessfully: 'Uspješno obrisano',
    sentSuccessfully: 'Uspješno poslato',
    activeStatusChanged: 'Status aktivan uspješno izmijenjen',
    defaultStatusChanged: 'Status default uspješno izmijenjen',
    primaryStatusChanged: 'Status primaran uspješno izmijenjen',
    anErrorOccured: 'Došlo je do greške, prijavite podršci',
    loadingCommentsFailed: 'Učitavanje komentara nije uspjelo',
    orderCanceled: 'Vožnja je uspješno otkazana',
    orderFinished: 'Vožnja je uspješno završena',
    saveChangesFirst: 'Prvo sačuvajte prethodno unesene izmjene',
    clientChanged: 'Kompanija uspješno promijenjena',
    changeFailed: 'Promjena nije uspjela, probajte opet',
    loginFailed: 'Korisničko ime ili lozinka nisu ispravni',
    clientSuspended: 'Vaša kompanija je suspendovana',
    userEmailDoesNotExists: 'Korisnik sa ovim e-mailom ne postoji',
    anErrorOccuredWithTranslation: 'Google prevodilac ne može prevesti ovaj tekst',
  },
  filters: {
    filter: 'Filter',
    currentYear: 'Tekuća godina',
    lastYear: 'Prethodna godina',
    lastQuarter: 'Posljednji kvartal',
    lastThreeYears: 'Prethodne tri godine',
    filterByDriver: 'Filtriraj po kolektoru',
    filterByClient: 'Filtriraj po klijentu',
    filterByOrganisation: 'Filtriraj po organizaciji',
    day: 'Dan',
    week: 'Nedelja',
    month: 'Mjesec',
    fromDate: 'Period od',
    toDate: 'Period do',
  },
  months: {
    jan: 'Januar',
    feb: 'Februar',
    mar: 'Mart',
    apr: 'April',
    may: 'Maj',
    jun: 'Jun',
    jul: 'Jul',
    aug: 'Avgust',
    sep: 'Septembar',
    oct: 'Oktobar',
    nov: 'Novembar',
    dec: 'Decembar',
    January: 'Januar',
    February: 'Februar',
    March: 'Mart',
    April: 'April',
    May: 'Maj',
    June: 'Jun',
    July: 'Jul',
    August: 'Avgust',
    September: 'Septembar',
    October: 'Oktobar',
    November: 'Novembar',
    December: 'Decembar',
  },
  usersPageHeaders: {
    firstName: 'Ime',
    lastName: 'Prezime',
    email: 'Email',
    role: 'Uloga',
    client: 'Klijent',
    created_at: 'Registrovan',
    active: 'Aktivno',
    actions: 'Akcije',
  },
  usersRoles: {
    masterAdmin: 'Master administrator',
    websiteAdmin: 'Administrator vebsajta',
    orderAdmin: 'Administrator naloga',
    client: 'Klijent',
    manager: 'Menadžer',
    collector: 'Kolektor',
  },
  usersPage: {
    newItem: 'Unos novog korisnika ',
    editItem: 'Izmjena korisnika',
    id: 'Id',
    firstName: 'Ime',
    lastName: 'Prezime',
    username: 'Korisničko ime',
    password: 'Lozinka',
    phoneNumber: 'Telefon',
    role: 'Uloga',
    email: 'Email',
    client: 'Klijent',
    percentage: 'Procenti',
    externalAssociate: 'Eksterni saradnik',
    image: 'Slika',
    deleteUser: 'Brisanje korisnika',
    deleteMessage: 'Da li ste sigurni da želite da izbrišete korisnika ',
    emailChanged: 'Promjena emaila',
    verificationEmailSent:
      'Zahtjev za promjenu email adrese je poslat. Da bi se završio proces i osigurala sigurnost korisničkog naloga, nova email adresa mora biti verifikovana  klikom na link za verifikaciju koji je poslat na novi email. Email adresa neće biti promijenjena dok ne bude uspješno verifikovana.',
    organisation: 'Organizacija'
  },
  ordersHeaders: {
    orderNumber: 'Broj naloga',
    orderStatus: 'Status',
    pickupLocation: 'Lokacija',
    collectorsName: 'Kolektor',
    clientsName: 'Klijent',
    startTime: 'Vrijeme polaska',
    actions: 'Akcije',
  },
  notificationsHeaders: {
    time: 'Vrijeme',
    notification: 'Obavještenje',
  },
  clientsHeaders: {
    name: 'Klijent',
    phoneNumber: 'Telefon',
    email: 'Email',
    address: 'Adresa',
    vat: 'PDV',
    pib: 'PIB',
    clientType: 'Tip klijenta',
    contactPersonName: 'Kontakt osoba',
    active: 'Aktivna',
    createdAt: 'Registrovan',
    organisation: 'Organizacija',
    created: 'Kreiran',
    actions: 'Akcije',
  },
  organisationsHeaders: {
    name: 'Naziv',
    created: 'Kreiran',
    actions: 'Akcije',
  },
  organisationsPage: {
    newItem: 'Unos nove organizacije ',
    name: 'Naziv',
    actions: 'Akcije',
    deleteOrganisation: 'Brisanje organizacije',
    deleteMessage: 'Da li ste sigurni da želite da obrišete ovu organizaciju?',
  },
  clientsPage: {
    newItem: 'Unos novog klijenta ',
    editItem: 'Izmjena klijenta',
    id: 'Id',
    companyName: 'Naziv kompanije',
    address: 'Adresa',
    phoneNumber: 'Telefon',
    email: 'Email',
    vat: 'PDV',
    pib: 'PIB',
    contactPersonName: 'Kontakt osoba',
    client: 'klijenta',
    deleteClient: 'Brisanje klijenta',
    deleteMessage: 'Da li ste sigurni da želite da obrišete ovog klijenta?',
    allOrders: 'Ukupan broj naloga',
    allOrdersAccepted: 'Ukupno prihvaćenih naloga',
    allOrdersCanceled: 'Ukupno odbijenih naloga',
    allUsers: 'Ukupan broj korisnika',
    areYouSure: 'Da li ste sigurni?',
    percentage: 'Procenat',
    location: 'Lokacija',
    clientType: 'Tip klijenta',
    organisation: 'Organizacija',
  },
  clientTypes: {
    micro: 'Mikro',
    small: 'Malo',
    medium: 'Srednje',
    large: 'Veliko',
  },
  joinRequestsPage: {
    deleteJoinRequest: 'Brisanje zahtjeva za registraciju',
    deleteMessage: 'Da li ste sigurni da želite da obrišete ovaj zahtjev za registraciju?',
  },
  sponsorsHeaders: {
    title: 'Naziv',
    description: 'Opis',
    link: 'Link',
    type: 'Tip',
    active: 'Aktivna',
    createdAt: 'Kreiran',
    moveUp: 'Premjesti iznad',
    moveDown: 'Premjesti ispod',
    actions: 'Akcije',
  },
  sponsorsPage: {
    newItem: 'Unos novog sponzora ',
    editItem: 'Izmjena sponzora',
    id: 'Id',
    name: 'Naziv',
    description: 'Opis',
    link: 'Link',
    type: 'Tip',
    logo: 'Logo',
    sponsor: 'sponzora',
    deleteSponsor: 'Brisanje sponzora',
    deleteMessage: 'Da li ste sigurni da želite da obrišete ovog sponzora?',
    areYouSure: 'Da li ste sigurni?',
  },
  mainBannerHeaders: {
    title: 'Naslov',
    description: 'Opis',
    cta_link: 'Link',
    active: 'Aktivna',
    createdAt: 'Registrovana',
    actions: 'Akcije',
  },
  mainBannerPage: {
    newItem: 'Unos novog banera ',
    editItem: 'Izmjena banera',
    id: 'Id',
    title: 'Naslov',
    description: 'Opis',
    link: 'Link',
    image: 'Slika',
    banner: 'baner',
    deleteBanner: 'Brisanje banera',
    deleteMessage: 'Da li ste sigurni da želite da obrišete ovaj baner?',
    areYouSure: 'Da li ste sigurni?',
  },
  articlesHeaders: {
    title: 'Naslov',
    intro_text: 'Kratak uvod',
    active: 'Aktivna',
    createdAt: 'Vrijeme kreiranja',
    publishDate: 'Vrijeme objave',
    actions: 'Akcije',
  },
  articlesPage: {
    newItem: 'Unos novog bloga ',
    editItem: 'Izmjena bloga',
    id: 'Id',
    title: 'Naslov',
    intro_text: 'Kratak uvod',
    body: 'Opis',
    link: 'Link',
    type: 'Tip',
    image: 'Slika',
    article: 'blog',
    deleteArticle: 'Brisanje bloga',
    deleteMessage: 'Da li ste sigurni da želite da obrišete ovaj blog?',
    areYouSure: 'Da li ste sigurni?',
  },
  eventsHeaders: {
    title: 'Naslov',
    event_date: 'Datum događaja',
    active: 'Aktivna',
    createdAt: 'Vrijeme kreiranja',
    actions: 'Akcije',
  },
  eventsPage: {
    newItem: 'Unos novog događaja ',
    editItem: 'Izmjena događaja',
    id: 'Id',
    title: 'Naslov',
    eventDate: 'Datum događaja',
    body: 'Opis',
    image: 'Slika',
    event: 'događaj',
    deleteEvent: 'Brisanje događaja',
    deleteMessage: 'Da li ste sigurni da želite da obrišete ovaj događaj?',
    areYouSure: 'Da li ste sigurni?',
  },
  aboutUsPage: {
    aboutUs: 'O nama',
    id: 'Id',
    title: 'Naslov',
    field_one: 'Polje 1',
    field_two: 'Polje 2',
    field_three: 'Polje 3',
    field_four: 'Polje 4',
    field_five: 'Polje 5',
    body: 'Opis',
  },
  contactMessagesHeaders: {
    title: 'Naziv',
    subject: 'Subject',
    body: 'Poruka',
    email: 'Email',
    phone: 'Broj telefona',
    createdAt: 'Vrijeme slanja',
    actions: 'Akcije',
  },
  contactMessagesPage: {
    id: 'Id',
    name: 'Naziv',
    body: 'Poruka',
    subject: 'Subject',
    email: 'Email',
    phone: 'Broj telefona',
  },
  albumsHeaders: {
    title: 'Naziv',
    description: 'Opis',
    active: 'Aktivna',
    createdAt: 'Vrijeme kreiranja',
    actions: 'Akcije',
  },
  albumsPage: {
    newItem: 'Unos novog albuma ',
    editItem: 'Izmjena albuma',
    id: 'Id',
    name: 'Naziv',
    description: 'Opis',
    album: 'album',
    deleteAlbum: 'Brisanje albuma',
    deleteMessage: 'Da li ste sigurni da želite da obrišete ovaj album?',
    areYouSure: 'Da li ste sigurni?',
  },
  albumItemsHeaders: {
    file: 'Slika',
    active: 'Aktivna',
    createdAt: 'Vrijeme kreiranja',
    actions: 'Akcije',
  },
  albumItemsPage: {
    newItem: 'Unos nove slike ',
    editItem: 'Izmjena slike',
    id: 'Id',
    image: 'Slika',
    albumItem: 'sliku',
    deleteAlbumItem: 'Brisanje slike',
    deleteMessage: 'Da li ste sigurni da želite da obrišete ovu sliku?',
    areYouSure: 'Da li ste sigurni?',
  },
  reportsHeaders: {
    expenses: 'Trošak',
    earnings: 'Zarada',
    profit: 'Profit',
    daysWorking: 'Radnih dana',
    usageIndex: 'Indeks korišćenja',
  },
  tables: {
    noDataText: 'Nema podataka',
    search: 'Pretraga',
    orders: 'Nalozi',
    ordersByPage: 'Naloga po strani',
    reportsByPage: 'Izvještaja po strani',
    users: 'Svi korisnici',
    usersByPage: 'Korisnika po strani',
    clients: 'Svi klijenti',
    organisations: 'Sve organizacije',
    clientsByPage: 'Klijenata po strani',
    organisationsByPage: 'Organizacija po strani',
    joinRequests: 'Svi zahtjevi za registraciju',
    joinRequestsByPage: 'Zahtjeva za registraciju po strani',
    sponsors: 'Svi sponzori',
    sponsorsByPage: 'Sponzora po strani',
    articles: 'Svi blogovi',
    articlesByPage: 'Blogova po strani',
    events: 'Svi događaji',
    eventsByPage: 'Događaja po strani',
    contactMessages: 'Sve poruke',
    contactMessagesByPage: 'Poruka po strani',
    mainBanner: 'Svi baneri',
    mainBannerByPage: 'Banera po strani',
    albums: 'Svi albumi',
    albumsByPage: 'Albuma po strani',
    albumItems: 'Sve slike u albumu',
    albumItemsByPage: 'Slika po strani',
    notifications: 'Obavještenja',
    notificationsByPage: 'Obavještenja po strani',
    all: 'Svi',
    of: 'od',
  },
  statistics: {
    numberOfAcceptedOrders: 'Broj prihvaćenih vožnji po vozaču',
    numberOfRejectedOrders: 'Broj odbijenih vožnji po vozaču',
    vehicleOrdersNumber: 'Broj vožnji po vozilima',
    vehicleProfit: 'Zarada po vozilima',
    monthOrdersNumber: 'Broj vožnji po mjesecima',
    driverProfit: 'Zarada po vozaču',
  },
  login: {
    username: 'Korisničko ime',
    password: 'Lozinka',
    rememberMe: 'Zapamti me',
    login: 'Prijavi se',
    email: 'Email',
    passwordChangeError:
      'Došlo je do greške prilikom mijenjanja lozinke. Molimo Vas pokušajte ponovo ili kontaktirajte podršku.',
  },
  order: {
    newOrder: 'Zakaži transfer',
    newDailyRent: 'Zakaži dnevni najam',
    areYouSure: 'Da li ste sigurni?',
    cancelOrder: 'Da li ste sigurni da želite da otkažete vožnju?',
    finishOrder: 'Da li ste sigurni da želite da završite vožnju?',
    newTransfer: 'Novi transfer',
    order: 'Nalog',
    comments: 'Komentari',
    id: 'Id',
    locationFrom: 'Polazište',
    locationTo: 'Odredište',
    description: 'Opis',
    extraInfo: 'Dodatne informacije',
    orderNotAccepted: 'Nisu prihvatili vožnju:',
    expectedTime: 'Očekivano trajanje',
    orderRequired: 'Vožnja obavezna',
    comment: 'Komentar',
    title: 'Naslov',
    pickupLocation: 'Lokacija preuzimanja',
    pickupTime: 'Vrijeme preuzimanja',
    collector: 'Kolektor',
    client: 'Klijent',
    glassAmount: 'Količina stakla',
    paperAmount: 'Količina papira',
    metalAmount: 'Količina metala',
    plasticAmount: 'Količina plastike',
  },
  orderStatus: {
    accepted: 'Prihvaćeno',
    client_pending: 'Klijent na čekanju',
    collector_pending: 'Kolektor na čekanju',
    canceled: 'Otkazano',
    completed: 'Završeno',
    deleted: 'Izbrisano',
  },
  customValidations: {
    fileType: `Tip fajla .{extension} nije podržan`,
    fileSize: 'Fajlovi ne mogu biti veći od {size}',
    fieldRequired: 'Polje {field} je obavezno',
    fieldType: 'Polje {field} mora biti {type}',
    fieldLengthMin: 'Polje {field} mora sadržati najmanje {length} karaktera',
    fieldLengthMax: 'Polje {field} ne smije biti duže od {length} karaktera',
    fieldUnique: 'Polje {field} mora biti jedinstveno',
    usersLimitExceeded: 'Dostignut limit za broj korinsika',
  },
  fieldTypes: {
    string: 'string',
    integer: 'broj',
    date: 'datum',
  },
  names: {
    username: 'korisničko ime',
    password: 'lozinka',
    choose_month: 'odaberi mjesec',
    first_name: 'ime',
    last_name: 'prezime',
    passport: 'pasoš',
    nationality: 'nacionalnost',
    description: 'opis',
    time_from: 'vrijeme od',
    time_to: 'vrijeme do',
    name: 'naziv',
    phone_number: 'broj telefona',
    client: 'klijent',
    role: 'uloga',
    image: 'slika',
    location: 'lokacija',
    location_from: 'polazište',
    location_to: 'odredište',
    comment: 'komentar',
    address: 'adresa',
    vat: 'pdv',
    pib: 'pib',
    contact_person_name: 'kontakt osoba',
    link: 'link',
    type: 'tip',
    logo: 'logo',
    email: 'email',
    percentage: 'procenat',
    clientName: 'ime klijenta',
    title: 'naslov',
    intro_text: 'kratak uvod',
    body: 'opis',
    pickup_time: 'vrijeme preuzimanja',
    glass_amount: 'količina stakla',
    paper_amount: 'količina papira',
    metal_amount: 'količina metala',
    plastic_amount: 'količina plastike',
    collector: 'kolektor',
    client_type: 'tip klijenta',
    company_name: 'naziv kompanije',
    event_date: 'datum događaja',
    field_one: 'polje 1',
    field_two: 'polje 2',
    field_three: 'polje 3',
    field_four: 'polje 4',
    field_five: 'polje 5',
  },
  weekDays: {
    monday: 'Ponedeljak',
    tuesday: 'Utorak',
    wednesday: 'Srijeda',
    thursday: 'Četvrtak',
    friday: 'Petak',
    saturday: 'Subota',
    sunday: 'Nedelja',
  },
  select: {
    noDataAvailable: 'Nema podataka',
  },
  statisticsPage: {
    reportType: 'Tip izvještaja',
    reportTime: 'Vrijeme izvještaja',
    vehicleUtilization: 'Izvještaj o korišćenju vozila',
    costEffectiveness: 'Izvještaj isplativosti vozila',
    generalReport: 'Generalni izvještaj',
  },
  languages: {
    me: 'Crnogorski',
    en: 'Engleski',
    ru: 'Ruski',
  },
};
