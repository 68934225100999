<template>
  <div>
    <v-navigation-drawer v-model="drawer" app>
      <div class="d-flex flex-column justify-space-between" style="height: 100dvh">
        <div>
          <v-divider></v-divider>

          <v-list dense nav>
            <v-list-group prepend-icon="mdi-file-document-multiple" v-if="[1, 3].includes(user.role_id)">
              <template v-slot:activator>
                <v-list-item-content class="text-left">
                  <v-list-item-title>{{ $t('sidebar.orders') }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <side-menu-item
                v-if="[1, 3].includes(user.role_id)"
                class_name="ml-4"
                :link="routePrefix.concat('/orders/calendar-view')"
                icon="mdi-calendar"
                :title="$t('sidebar.calendar')"
                :tooltip="$t('sidebar.calendar')" />
              <side-menu-item
                v-if="[1, 3].includes(user.role_id)"
                class_name="ml-4"
                :link="routePrefix.concat('/orders/tabular-view')"
                icon="mdi-table"
                :title="$t('sidebar.tabularView')"
                :tooltip="$t('sidebar.tabularView')" />
            </v-list-group>

            <v-list-group prepend-icon="mdi-cogs">
              <template v-slot:activator>
                <v-list-item-content class="text-left">
                  <v-list-item-title>{{ $t('sidebar.administration') }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <side-menu-item
                class_name="ml-4"
                :link="routePrefix.concat('/users')"
                icon="mdi-account-group"
                :title="$t('sidebar.users')"
                :tooltip="$t('sidebar.usersAdministration')" />

              <side-menu-item
                class_name="ml-4"
                :link="routePrefix.concat('/clients')"
                icon="mdi-office-building"
                :title="$t('sidebar.clients')"
                :tooltip="$t('sidebar.clientsAdministration')" />

              <side-menu-item
                  class_name="ml-4"
                  :link="routePrefix.concat('/organisations')"
                  icon="mdi-google-circles"
                  :title="$t('sidebar.organisations')"
                  :tooltip="$t('sidebar.organisationsAdministration')" />

              <side-menu-item
                class_name="ml-4"
                :link="routePrefix.concat('/join-requests')"
                icon="mdi-cloud-sync"
                :title="$t('sidebar.joinRequests')"
                :tooltip="$t('sidebar.joinRequestsAdministration')" />
            </v-list-group>

            <v-list-group prepend-icon="mdi-cogs" v-if="[1, 2].includes(user.role_id)">
              <template v-slot:activator>
                <v-list-item-content class="text-left">
                  <v-list-item-title>{{ $t('sidebar.administrationWebsite') }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <side-menu-item
                v-if="[1, 2].includes(user.role_id)"
                class_name="ml-4"
                :link="routePrefix.concat('/main-banner')"
                icon="mdi-image"
                :title="$t('sidebar.mainBanner')"
                :tooltip="$t('sidebar.mainBannerAdministration')" />

              <side-menu-item
                v-if="[1, 2].includes(user.role_id)"
                class_name="ml-4"
                :link="routePrefix.concat('/sponsors')"
                icon="mdi-office-building"
                :title="$t('sidebar.sponsors')"
                :tooltip="$t('sidebar.sponsorsAdministration')" />

              <side-menu-item
                v-if="[1, 2].includes(user.role_id)"
                class_name="ml-4"
                :link="routePrefix.concat('/articles')"
                icon="mdi-post"
                :title="$t('sidebar.article')"
                :tooltip="$t('sidebar.articleAdministration')" />

              <side-menu-item
                v-if="[1, 2].includes(user.role_id)"
                class_name="ml-4"
                :link="routePrefix.concat('/about-us')"
                icon="mdi-account-group"
                :title="$t('sidebar.aboutUs')"
                :tooltip="$t('sidebar.aboutUsAdministration')" />

              <side-menu-item
                v-if="[1, 2].includes(user.role_id)"
                class_name="ml-4"
                :link="routePrefix.concat('/albums')"
                icon="mdi-image-multiple"
                :title="$t('sidebar.albums')"
                :tooltip="$t('sidebar.albumsAdministration')" />

              <side-menu-item
                v-if="[1, 2].includes(user.role_id)"
                class_name="ml-4"
                :link="routePrefix.concat('/events')"
                icon="mdi-calendar-multiple"
                :title="$t('sidebar.events')"
                :tooltip="$t('sidebar.eventsAdministration')" />

              <side-menu-item
                v-if="[1, 2].includes(user.role_id)"
                class_name="ml-4"
                :link="routePrefix.concat('/contact-messages')"
                icon="mdi-message"
                :title="$t('sidebar.contactMessages')"
                :tooltip="$t('sidebar.contactMessagesAdministration')" />
            </v-list-group>

            <side-menu-item
              :link="routePrefix.concat('/statistics')"
              icon="mdi-chart-bar"
              :title="$t('sidebar.statistics')"
              :tooltip="$t('sidebar.statistics')" />
          </v-list>
        </div>

        <div>
          <v-divider></v-divider>
          <v-list dense nav>
            <v-dialog v-model="dialog" max-width="450" persistent>
              <template v-slot:activator="{ on: dialog }">
                <v-tooltip right>
                  <template v-slot:activator="{ on: tooltip, attrs }">
                    <v-list-item v-bind="attrs" v-on="{ ...tooltip, ...dialog }">
                      <v-list-item-icon>
                        <v-icon>mdi-logout</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="text-left">
                        <v-list-item-title>{{ $t('sidebar.logout') }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <span>{{ $t('sidebar.logout') }}</span>
                </v-tooltip>
              </template>

              <v-card>
                <v-card-title class="blue darken-1 title white--text font-weight-bold">
                  {{ $t('sidebar.logout') }}
                  <v-spacer></v-spacer>
                  <v-icon class="mdi mdi-close" style="color: white" @click="dialog = false"></v-icon>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    {{ $t('sidebar.logoutText') }}
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="primary" text @click="logout">
                    {{ $t('buttons.yes') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-list>
        </div>
      </div>
    </v-navigation-drawer>

    <v-app-bar app class="primary" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <div class="d-flex align-center" @click="this.redirectToHomePage">
        <v-img
          :class="{ displayLogo: [1, 2, 3].includes(user.role_id) }"
          alt="Logo"
          class="shrink mr-2"
          contain
          height="60"
          src="@/assets/logo2.png"
          transition="scale-transition"
          width="200" />
      </div>

      <v-spacer></v-spacer>

      <template v-if="this.$store.state.auth.authenticated && [1, 3].includes(user.role_id)">
        <div class="text-center">
          <template>
            <v-btn class="primary secondary mr-8" @click="displayFormAndDisableOptions()">
              + {{ $t('header.newOrder') }}
            </v-btn>
          </template>
        </div>
      </template>

      <OrderModal :disabled="false" @disableDisplay="closeModal" :isModalOpen="displayForm" />

      <template>
        <v-tooltip left>
          <template v-slot:activator="{ on: tooltip }">
            <router-link to="/notifications" class="black--text text-decoration-none">
              <v-btn text v-on="{ ...tooltip }">
                <v-icon> mdi-bell </v-icon>
              </v-btn>
            </router-link>
          </template>
          <span>
            {{ $t('header.notifications') }}
          </span>
        </v-tooltip>
      </template>
    </v-app-bar>

    <v-main class="mb-16">
      <router-view :key="$route.fullPath" class="mb-5" />
    </v-main>

    <Footer />

    <v-snackbar v-model="snackbar.active" :color="snackbar.color" :timeout="2000" bottom>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.active = false">
          {{ $t('snackbar.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import SideMenuItem from '@/components/SideMenuItem';
import OrderModal from '@/components/orders/OrderModal.vue';
import state from '@/store';
import router from '@/router';
import Footer from '@/components/Footer';

export default {
  name: 'MainLayout',
  data() {
    return {
      user: {
        name: '',
        email: '',
        role_id: null,
      },
      organisationId: null,
      organisations: [],
      drawer: false,
      dialog: false,
      preview: true,
      menu: false,
      routePrefix: process.env.VUE_APP_ROUTE_PREFIX,
      snackbar: {
        active: false,
        color: '',
        text: '',
      },
      displayForm: false,
    };
  },
  computed: {
    item_length: function (array) {
      return array.length;
    },
  },
  components: {
    SideMenuItem,
    OrderModal,
    Footer,
  },
  created() {
    this.loadUser();
  },
  methods: {
    closeModal() {
      this.displayForm = false;
    },
    loadUser() {
      this.user = this.$store.getters['auth/user'];
    },

    logout() {
      state.dispatch('auth/logout');
      this.dialog = false;
      router.push({ name: 'Login' });
    },

    redirectToHomePage() {
      router.push({ name: 'HomeCalendarView' });
    },

    color(active) {
      return active ? 'green' : 'red';
    },

    displayFormAndDisableOptions() {
      this.displayForm = true;
    },
  },

  watch: {
    displayForm: {
      handler() {
        if (this.displayForm) {
          this.selectedOpen = true;
        }
      },
    },
  },
};
</script>

<style>
@media only screen and (max-width: 500px) {
  .displayLogo {
    display: none;
  }
}

.v-list-item--active {
  color: #298860 !important;
}
</style>
