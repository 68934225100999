import { getDistanceBetweenTwoPlaces } from '@/api/googleMapsAPI';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getDistanceBetweenTwoPlaces(state, item) {
      return getDistanceBetweenTwoPlaces(item);
    },
  },
};
