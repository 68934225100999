import { getAllEvents, saveEvent, updateEvent, deleteEvent } from '@/api/events';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllEvents() {
      return getAllEvents();
    },
    async saveEvent(state, item) {
      return saveEvent(item);
    },
    async updateEvent(state, item) {
      return updateEvent(item);
    },
    async deleteEvent(state, item) {
      return deleteEvent(item);
    },
  },
  getters: {},
};
