import i18n from '@/i18n/i18n';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    errorMapper(state, item) {
      const data = {
        'AUTH-USERNAME-0001': i18n.t('customValidations.fieldRequired', {
          field: i18n.t('names.username'),
        }),
        'AUTH-USERNAME-0002': i18n.t('customValidations.fieldType', {
          field: i18n.t('names.username'),
          type: i18n.t('fieldTypes.string'),
        }),
        'AUTH-PASS-0001': i18n.t('customValidations.fieldRequired', {
          field: i18n.t('names.password'),
        }),
        'SUBS-CHANGE-0001': 'SUBS-CHANGE-0001',
        'SUBS-CHANGE-0002': 'SUBS-CHANGE-0002',
        'CSR-0001': 'CSR-0001',
        'CSR-0002': 'CSR-0002',
        'CSR-0003': 'CSR-0003',
        'CSR-0004': 'CSR-0004',
        'CSR-0005': 'CSR-0005',
        'CSR-0006': 'CSR-0006',
        'SCR-0007': 'SCR-0007',
        'CVM-0002': 'CVM-0002',
        'CVM-0003': 'CVM-0003',
        'CVM-0004': 'Datum od je obavezan',
        'CVM-0005': 'Izaberite validan datum',
        'CVM-0006': 'Datum do je obevezan',
        'CVM-0007': 'Izaberite validan datum do',
        'GL-001': 'GL-001',
        'GL-002': 'GL-002',
        'GL-003': 'GL-003',
        'GL-004': 'GL-004',
        'GL-005': 'GL-005',
        'GL-006': 'GL-006',
        'GL-007': 'GL-007',
        'DRIVING-COMMENT-0001': i18n.t('customValidations.fieldRequired', {
          field: i18n.t('names.comment'),
        }),
        'DRIVING-COMMENT-0004': 'DRIVING-COMMENT-0004',
        'DRIVING-COMMENT-0005': 'Ocjena mora bit validan broj',
        'DRIVING-COMMENT-0006': 'DRIVING-COMMENT-0006',
        'DRIVING-COMMENT-0007': 'Vožnja je obavezna',
        'DRIVING-PROPOSAL-0001': 'DRIVING-PROPOSAL-0001',
        'DRIVING-PROPOSAL-0002': 'Polje potvrditi do mora biti datum',
        'DRIVING-PROPOSAL-0003': 'DRIVING-PROPOSAL-0003',
        'DRIVING-PROPOSAL-0004': 'DRIVING-PROPOSAL-0004',
        'DRIVING-PROPOSAL-0005': 'DRIVING-PROPOSAL-0005',
        'DRIVING-PROPOSAL-RESPONSE-0001': 'DRIVING-PROPOSAL-RESPONSE-0001',
        'DRIVING-PROPOSAL-RESPONSE-0002': 'DRIVING-PROPOSAL-RESPONSE-0002',
        'DRIVING-0001': 'Polazište je obavezno',
        'DRIVING-0002': 'DRIVING-0002',
        'DRIVING-0003': 'DRIVING-0003',
        'DRIVING-0004': 'DRIVING-0004',
        'DRIVING-0005': 'Vrijeme polaska mora biti validan datum',
        'DRIVING-0006': 'Razdaljina mora biti validan broj',
        'DRIVING-0007': 'DRIVING-0007',
        'DRIVING-0008': 'Vrijeme dolaska mora biti validan datum',
        'DRIVING-0009': 'Vrijeme povratke mora biti validan datum',
        'DRIVING-0010': 'DRIVING-0010',
        'DRIVING-0011': 'Cijena mora biti validan broj',
        'DRIVING-0012': 'DRIVING-0012',
        'DRIVING-0013': 'Odredište je obavezno',
        'DRIVING-0014': 'DRIVING-00014',
        'DRIVING-0015': 'Vrijeme polaska je obevezno',
        'DRIVING-0016': 'DRIVING-00016',
        'DRIVING-0017': 'DRIVING-00017',
        'DRIVING-0018': 'DRIVING-00018',
        'DRIVING-0019': 'DRIVING-00019',
        'DRIVING-0020': 'DRIVING-00020',
        'DRIVING-0021': 'DRIVING-00021',
        'DRIVING-0022': 'DRIVING-00022',
        'DRIVING-0023': 'DRIVING-00023',
        'DRIVING-0024': 'DRIVING-00024',
        'DRIVING-0025': 'DRIVING-00025',
        'DRIVING-0026': 'DRIVING-00026',
        'DRIVING-0027': 'DRIVING-00027',
        'DRIVING-0028': 'DRIVING-00028',
        'DRIVING-0029': 'DRIVING-00029',
        'DRIVING-0030': 'DRIVING-00030',
        'DRIVING-0031': 'DRIVING-00031',
        'DRIVING-0032': 'DRIVING-00032',
        'DRIVING-0033': 'DRIVING-00033',
        'DRIVING-0034': 'DRIVING-00034',
        'DRIVING-0035': 'DRIVING-00035',
        'DRIVING-0036': 'DRIVING-00036',
        'DRIVING-0037': 'DRIVING-00037',
        'DRIVING-0038': 'DRIVING-00038',
        'DRIVING-0039': 'DRIVING-00039',
        'DRIVING-0040': 'DRIVING-00040',
        'DRIVING-0041': 'DRIVING-00041',
        'DRIVING-0042': 'DRIVING-00042',
        // vrati se na external request
        'NOTIFICATION-0001': 'NOTIFICATION-0001',
        'NOTIFICATION-0002': 'NOTIFICATION-0002',
        'NOTIFICATION-0003': 'NOTIFICATION-0003',
        'NOTIFICATION-0004': 'NOTIFICATION-0004',
        'NOTIFICATION-0005': 'NOTIFICATION-0005',
        'NOTIFICATION-0006': 'NOTIFICATION-0006',
        'NOTIFICATION-0007': 'NOTIFICATION-0007',
        'NOTIFICATION-0008': 'NOTIFICATION-0008',
        'NOTIFICATION-0009': 'NOTIFICATION-0009',
        'ORGANISATION-0001': 'Ime organizacije je obavezno',
        'ORGANISATION-0002': 'Ime organizacije mora biti validno',
        'ORGANISATION-0003': 'Ime organizacije ne smije biti duže od 255 karaktera',
        'ORGANISATION-0004': 'ORGANISATION-0004',
        'ORGANISATION-0005': 'Adresa mora bit validna',
        'ORGANISATION-0006': 'Adresa ne smije biti duža od 255 karektera',
        'ORGANISATION-0007': 'PDV mora biti validan',
        'ORGANISATION-0008': 'PDV ne smije biti duži od 255 karaktera',
        'ORGANISATION-0009': 'ORGANISATION-0009',
        'ORGANISATION-0010': 'ORGANISATION-0010',
        'ORGANISATION-0011': 'ORGANISATION-0011',
        'ORGANISATION-0012': 'ORGANISATION-0012',
        'ORGANISATION-0013': 'ORGANISATION-0013',
        'ORGANISATION-0014': 'ORGANISATION-0014',
        'ORGANISATION-0015': 'ORGANISATION-0015',
        'ORGANISATION-0016': 'ORGANISATION-0016',
        'ORGANISATION-0017': i18n.t('customValidations.fieldUnique', {
          field: i18n.t('names.vat'),
        }),
        'ORGANISATION-0018': i18n.t('customValidations.fieldUnique', {
          field: i18n.t('names.company_id'),
        }),
        'ORGANISATION-0019': i18n.t('customValidations.fieldUnique', {
          field: i18n.t('names.email'),
        }),
        'ORGANISATION-0020': 'ORGANISATION-0020',
        'ORGANISATION-0021': 'ORGANISATION-0021',
        'ORGANISATION-0022': 'ORGANISATION-0022',
        'ORGANISATION-0023': 'ORGANISATION-0023',
        'ORGANISATION-0024': 'ORGANISATION-0024',
        'PAYMENT-METHOD-0001': 'Naziv metode plaćanja je obavezan',
        'PAYMENT-METHOD-0002': 'Naziv metode plaćanja mora bit validan',
        'PAYMENT-METHOD-0003': 'Naziv metode plaćanja ne smije bit duži od 255 karaktera',
        'PAYMENT-METHOD-0004': 'PAYMENT-METHOD-0004',
        'PAYMENT-METHOD-0005': 'PAYMENT-METHOD-0005',
        'PAYMENT-METHOD-0006': 'PAYMENT-METHOD-0006',
        'PROFILE-IMAGE-0001': 'PROFILE-IMAGE-0001',
        'PROFILE-IMAGE-0002': 'PROFILE-IMAGE-0002',
        'PROFILE-0001': 'Ime mora bit validno',
        'PROFILE-0002': 'Ime ne smije biti duže od 255 karaktera',
        'PROFILE-0003': 'Pri kreiranju korisnika ime je obavezno',
        'PROFILE-0004': 'Prezime mora biti validno',
        'PROFILE-0005': 'Prezime ne smije biti duže od 255 karaktera',
        'PROFILE-0006': 'Pri kreiranju korisnika prezime je obavezno',
        'PROFILE-0007': 'PROFILE-0007',
        'PROFILE-0008': 'PROFILE-0008',
        'PROFILE-0009': 'Adresa mora biti validna',
        'PROFILE-0010': 'Adresa ne smije biti duža od 255 karaktera',
        'PROFILE-0011': 'PROFILE-0011',
        'PROFILE-0012': 'Broj ne smije biit duži od 255 karaktera',
        'PROFILE-0013': 'PROFILE-0013',
        'SUBSCRIPTION-0001': 'SUBSCRIPTION-0001',
        'SUBSCRIPTION-0002': 'SUBSCRIPTION-0002',
        'SUBSCRIPTION-0003': 'SUBSCRIPTION-0003',
        'SUBSCRIPTION-0004': 'SUBSCRIPTION-0004',
        'SUBSCRIPTION-0005': 'SUBSCRIPTION-0005',
        'SUBS-0001': i18n.t('customValidations.noActiveSubscription'),
        'SUBS-0002': i18n.t('customValidations.usersLimitExceeded'),
        'USER-0001': 'Unesite pravilno korisničko ime',
        'USER-0002': 'Korisničko ime ne smije biti duže od 255 karaktera',
        'USER-0003': 'Korisničko ime je obavezno pri kreiranju naloga',
        'USER-0004': 'Email mora biti pravilnog formata',
        'USER-0005': 'Email je obavezan',
        'USER-0007': 'Lozinka je obavezna',
        'USER-0008': 'Lozinka ne smije biti duža od 255 karaktera',
        'USER-0009': 'USER-0009',
        'USER-0010': 'USER-0010',
        'USER-0011': 'USER-0011',
        'USER-0012': i18n.t('customValidations.fieldUnique', {
          field: i18n.t('names.email'),
        }),
        'USER-0013': i18n.t('customValidations.fieldUnique', {
          field: i18n.t('names.username'),
        }),
        'USER-0014': 'USER-0014',
        'VM-0001': 'VM-0001',
        'VM-0002': i18n.t('customValidations.fieldRequired', {
          field: i18n.t('names.description'),
        }),
        'VM-0003': i18n.t('customValidations.fieldType', {
          field: i18n.t('names.description'),
          type: i18n.t('fieldTypes.string'),
        }),
        'VM-0004': i18n.t('customValidations.fieldRequired', {
          field: i18n.t('names.time_from'),
        }),
        'VM-0005': i18n.t('customValidations.fieldType', {
          field: i18n.t('names.time_from'),
          type: i18n.t('fieldTypes.date'),
        }),
        'VM-0006': i18n.t('customValidations.fieldRequired', {
          field: i18n.t('names.time_to'),
        }),
        'VM-0007': i18n.t('customValidations.fieldType', {
          field: i18n.t('names.time_to'),
          type: i18n.t('fieldTypes.date'),
        }),
        'VM-0009': 'VM-0009',
        'EXPORT-001': 'EXPORT-001',
        'from.required': i18n.t('customValidations.fieldRequired', {
          field: i18n.t('names.time_from'),
        }),
        'EXPORT-003': 'EXPORT-003',
        'EXPORT-004': i18n.t('customValidations.fieldRequired', {
          field: i18n.t('names.time_to'),
        }),
        'EXPORT-005': i18n.t('excelExport.to_date_range'),
        'EXPORT-006': i18n.t('excelExport.overlap_range'),
      };

      return data[item.split(' ')[0]] || i18n.t('snackbar.anErrorOccured');
    },
  },
  getters: {},
};
