import { getHttpClient } from './client';

export const getAllSponsors = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/sponsor').then((response) => response.data);
};

export const saveSponsor = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/sponsor', 'POST', data).then((response) => response.data);
};

export const updateSponsor = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/sponsor', 'POST', data).then((response) => response.data);
};

export const deleteSponsor = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/sponsor/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const toggleSponsorActiveStatus = async (data) => {
  return await getHttpClient(
    process.env.VUE_APP_API_URL + '/change-sponsor-active-status/' + data.id,
    'POST'
  ).then((response) => response.data);
};

export const orderSponsors = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/order-sponsors', 'POST', data).then((response) => response.data);
};