<template>
  <v-layout>
    <v-app-bar app class="primary" dark>
      <template></template>
    </v-app-bar>

    <v-main class="mb-16">
      <router-view :key="$route.fullPath" class="mb-5" />
    </v-main>

    <Footer />
  </v-layout>
</template>

<script>
import Footer from '@/components/Footer';

export default {
  components: {
    Footer,
  },
  name: 'HeaderEmpty',
  data() {
    return {};
  },
  methods: {},
};
</script>
