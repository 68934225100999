import {
  commissionPerDriver,
  downloadPDF,
  driverAcceptActivity,
  driverRejectActivity,
  getProfitabilityQuarterReport,
  getProfitabilityYearReport,
  getUtilizationQuarterReport,
  getUtilizationYearReport,
  numberOfDrivings,
  revenuePerVehicle,
  vehicleActivity,
} from '@/api/statistic';

export default {
  namespaced: true,
  mutations: {},

  actions: {
    async numberOfDrivings(state, item) {
      return numberOfDrivings(item);
    },
    async revenuePerVehicle(state, item) {
      return revenuePerVehicle(item);
    },
    async vehicleActivity(state, item) {
      return vehicleActivity(item);
    },
    async driverAcceptActivity(state, item) {
      return driverAcceptActivity(item);
    },
    async driverRejectActivity(state, item) {
      return driverRejectActivity(item);
    },
    async commissionPerDriver(state, item) {
      return commissionPerDriver(item);
    },
    getProfitabilityQuarterReport(state, item) {
      return getProfitabilityQuarterReport(item);
    },

    getProfitabilityYearReport(state, item) {
      return getProfitabilityYearReport(item);
    },

    getUtilizationQuarterReport(state, item) {
      return getUtilizationQuarterReport(item);
    },

    getUtilizationYearReport(state, item) {
      return getUtilizationYearReport(item);
    },
    async downloadPDF() {
      return downloadPDF();
    },
  },
};
