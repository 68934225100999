import { getHttpClient } from './client';

export const getAllMainBanners = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/main-banner-picture').then((response) => response.data);
};

export const saveMainBanner = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/main-banner-picture', 'POST', data).then(
    (response) => response.data
  );
};

export const updateMainBanner = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/main-banner-picture', 'POST', data).then(
    (response) => response.data
  );
};

export const deleteMainBanner = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/main-banner-picture/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};
