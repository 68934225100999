import { getHttpClient } from './client';

export const getAllJoinRequests = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/join-request').then((response) => response.data);
};

export const deleteJoinRequest = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/join-request/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};
