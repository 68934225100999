import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './styles/main.scss';
import i18n from './i18n/i18n';
import vuetify from './plugins/vuetify';
import * as VueGoogleMaps from 'gmap-vue';
import './validations/validations';
import VueMeta from 'vue-meta';
// Import the functions you need from the SDKs you need
// import { initializeApp } from 'firebase/app';
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';

Vue.use(VueMeta);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: ['places', 'routes'],
  },
  installComponents: true,
});

Vue.config.productionTip = false;

// const firebaseConfig = {
  // apiKey: 'AIzaSyB6XqxdwHQ9GGYAdZ-6Sk3oM3A23mZ7wU0',
  // authDomain: 'limoexpress-e1849.firebaseapp.com',
  // projectId: 'limoexpress-e1849',
  // storageBucket: 'limoexpress-e1849.appspot.com',
  // messagingSenderId: '830423176156',
  // appId: '1:830423176156:web:573d57c409c6f9d9c5f519',
  // measurementId: 'G-21M42QM5GK',
// };

// Check if the browser supports necessary features for Firebase Messaging
// const isMessagingSupported = 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window;

// if (isMessagingSupported) {
//   try {
//     const app = initializeApp(firebaseConfig);
//     const messaging = getMessaging(app);

//     Notification.requestPermission()
//       .then((allowed) => {
//         if (allowed === 'granted') {
//           getToken(messaging, {
//             vapidKey: 'BKl1zo_4SnG6cBAzSnThsGw98U_jVGN1NW2-syrPAlRuAD93Y_aR-na6qTf8TaAxR6lbBSNb1xpkNykvsl6iFhE',
//           })
//             .then((token) => {
//               Vue.prototype.$deviceToken = token;
//             })
//             .catch(() => {
//               getToken(messaging, {
//                 vapidKey: 'BKl1zo_4SnG6cBAzSnThsGw98U_jVGN1NW2-syrPAlRuAD93Y_aR-na6qTf8TaAxR6lbBSNb1xpkNykvsl6iFhE',
//               })
//                 .then((token) => {
//                   Vue.prototype.$deviceToken = token;
//                 })
//                 .catch((err) => {
//                   // eslint-disable-next-line no-console
//                   console.error('Error getting token:', err);
//                 });
//             });
//         }
//       })
//       .catch((err) => {
//         // eslint-disable-next-line no-console
//         console.error('Error requesting permission:', err);
//       });

//     onMessage(messaging, (payload) => {
//       const noteTitle = payload.notification.title;
//       const noteOptions = {
//         body: payload.notification.body,
//         icon: 'favicon.png',
//         vibrate: 5,
//       };
//       let notification = new Notification(noteTitle, noteOptions);
//       notification.addEventListener('click', () => {
//         if (router.history.current.name === 'HomeCalendarView') {
//           history.pushState({}, '', `/?d_id=${payload.data.driving_id}`);
//           store.commit('updateNotificationDriving', payload.data.driving_id);
//         } else {
//           router.push({
//             name: 'HomeCalendarView',
//             query: { d_id: payload.data.driving_id },
//           });
//         }
//       });
//     });
//   } catch (error) {
//     // eslint-disable-next-line no-console
//     console.error('Firebase initialization error:', error);
//   }
// } else {
//   // eslint-disable-next-line no-console
//   console.warn('Firebase Messaging is not supported in this browser. Some features may not work as expected.');
// }

const VueApp = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

export default VueApp;
