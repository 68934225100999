<template>
  <v-tooltip right>
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <v-list-item :class="class_name" :to="link" link :disabled="disabled">
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="text-left">
            <v-list-item-title>{{ title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </template>
    <span v-if="disabled">{{ $t('sidebar.unaccessibleRoutes') }}</span>
    <span v-else>{{ tooltip === '' ? title : tooltip }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'SideMenuItem',
  props: {
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    class_name: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
