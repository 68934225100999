import { getHttpClient } from './client';

export const getAllAlbumItems = async (id) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/album/' + id).then((response) => response.data);
};

export const saveAlbumItem = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/album-item', 'POST', data).then(
    (response) => response.data
  );
};

export const deleteAlbumItem = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/album-item/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};
