export const defaultUser = {
  id: null,
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  role: null,
  client: null,
};

export const defaultClient = {
  id: null,
  company_name: '',
  email: '',
  address: '',
  phone: '',
  vat_number: '',
  pib: '',
  contact_person_name: '',
  location_coordinates: '',
  active: 1,
};

export const defaultOrganisation = {
  id: null,
  name: ''
};

export const defaultJoinRequest = {
  id: null,
  name: '',
  email: '',
  address: '',
  phone: '',
  vat_number: '',
  pib: '',
  contact_person_name: '',
  location_coordinates: '',
  active: 1,
};

export const defaultSponsor = {
  id: null,
  title: '',
  title_en: '',
  title_ru: '',
  description: '',
  description_en: '',
  description_ru: '',
  link: '',
  logo: null,
  type: '',
  active: 1,
};

export const defaultOrderComment = {
  order_id: null,
  rate: 0,
  body: null,
};

export const defaultOrder = {
  id: '',
  title: '',
  description: '',
  glass_amount: 0,
  paper_amount: 0,
  metal_amount: 0,
  plastic_amount: 0,
  pickup_time: '',
  pickup_location: '',
  client_id: null,
  collector_id: null,
};

export const defaultAlbum = {
  id: '',
  title: '',
  title_en: '',
  title_ru: '',
  description: '',
  description_en: '',
  description_ru: '',
};

export const defaultAlbumItem = {
  id: '',
  item: '',
  album_id: '',
};

export const defaultContactMessage = {
  id: '',
  title: '',
  body: '',
  subject: '',
  email: '',
  phone: '',
};

export const defaultArticle = {
  id: '',
  title: '',
  title_en: '',
  title_ru: '',
  intro_text: '',
  intro_text_en: '',
  intro_text_ru: '',
  body: '',
  body_en: '',
  body_ru: '',
  publish_date: '',
  picture: null,
};

export const defaultEvent = {
  id: '',
  title: '',
  title_en: '',
  title_ru: '',
  body: '',
  body_en: '',
  body_ru: '',
  picture: null,
  date: null,
};

export const defaultMainBanner = {
  id: '',
  title: '',
  title_en: '',
  title_ru: '',
  description: '',
  description_en: '',
  description_ru: '',
  cta_link: '',
  image: null,
};
